<template>
  <div class="bg-gradient-light">
    <div class="middle">
      <b-row class="p-2">
        <b-col class="col-md-9">
          <b-progress class="mt-5 bg-gradient-light" max="100" show-value height="0.5rem">
            <b-progress-bar value="25" variant="success">
              <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">1</h5>
              <span class="text-dark position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Pickyourcar") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="success">
              <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">2</h5>

              <span class="text-dark position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Insuranceextras") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="warning">
              <h5 class="mb-0 position-absolute text-white bg-warning px-2 rounded-circle">3</h5>

              <span class="text-dark position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Driversdetails") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="secondary">
              <h5 class="mb-0 position-absolute text-white bg-secondary px-2 rounded-circle">4</h5>
              <span class="text-black-50 position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Submit") }}</span>
            </b-progress-bar>
          </b-progress>
          <b-card class="mt-5">
            <b-card-title>{{ $t("Driversdetails") }}</b-card-title>
            <b-form class="needs-validation" ref="form" novalidate>
              <b-row>
                <b-form-group class="col-12 col-sm-6" id="input-group-1" :label="$t('FirstName')+':'">
                  <b-form-input trim v-model="order.firstName" required :placeholder="$t('EnterFirstName')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-2" :label="$t('LastName')+':'">
                  <b-form-input trim v-model="order.lastName" required :placeholder="$t('EnterLastName')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-3" :label="$t('EmailAddress')+':'">
                  <b-form-input trim v-model="order.email" type="email" required :placeholder="$t('EnterEmail')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-4" :label="$t('PhoneNumber')+':'">
                  <b-row no-gutters>
                    <b-form-select
                      @blur.native="codeFocus = false"
                      @focus.native="codeFocus = true"
                      class="col-3"
                      v-model="order.countryCode"
                      @change="$refs.phoneRef.focus()"
                      :options="codeOptions"
                      :text-field="codeFocus ? 'text' : 'value'"
                      required
                    ></b-form-select>
                    <b-form-input ref="phoneRef" class="col-9" trim v-model="order.tel" type="tel" required :placeholder="$t('EnterPhoneNumber')"></b-form-input>
                  </b-row>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-5" :label="$t('FlightNumber')+':'">
                  <b-form-input trim v-model="order.flightNumber" :placeholder="$t('EnterFlightNumber')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-6" :label="$t('DriverBirthdate')+':'">
                  <flatPickr v-model="order.birthdate" :config="dateConfig"></flatPickr>
                  <template slot="description">
                    <b-form-text :text-variant="birthdateWarning[0].type" class="font-weight-bold">{{ birthdateWarning[0].desc }}</b-form-text>
                  </template>
                </b-form-group>
                <div class="w-100 border-top my-2"></div>
                <b-col cols="12">
                  <h5>
                    {{ $t("SecondDriver") }}
                    <b-badge variant="success" class="ml-2 h6">{{ $t("FREE") }}</b-badge>
                  </h5>
                </b-col>
                <b-form-group class="col-12 col-sm-6" id="input-group-7" :label="$t('FirstName')+':'">
                  <b-form-input trim v-model="order.secondDriver.firstName" :placeholder="$t('EnterFirstName')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-8" :label="$t('LastName')+':'">
                  <b-form-input trim v-model="order.secondDriver.lastName" :placeholder="$t('EnterLastName')"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" id="input-group-9" :label="$t('DriverBirthdate')+':'">
                  <flatPickr v-model="order.secondDriver.birthdate" :config="dateConfig"></flatPickr>
                  <template slot="description">
                    <b-form-text :text-variant="birthdateWarning[1].type" class="font-weight-bold">{{ birthdateWarning[1].desc }}</b-form-text>
                  </template>
                </b-form-group>
                <div class="w-100 border-top my-2"></div>
                <b-col cols="12">
                  <h5>
                    {{ $t("Message") }}
                    <b-badge variant="secondary" class="ml-2 h6">{{ $t("Max140") }}</b-badge>
                  </h5>
                </b-col>
                <b-form-group class="col-12" id="input-group-7">
                  <b-form-textarea rows="4" trim v-model="order.remark" :placeholder="$t('EnterYourMessage')"></b-form-textarea>
                </b-form-group>
                <div class="w-100 border-top my-2"></div>
                <b-form-group class="mb-0" v-if="$i18n.locale === 'en'">
                  <b-col cols="12" class="h3 text-danger">Important Note</b-col>
                  <ol>
                    <li>A Pre-authorisation bond will be on hold under the driver’s credit card when you check-in.</li>
                    <li>Your Credit Card or Debit Card must have VISA or MASTERCARD sign.</li>
                    <li>
                      The amount of pre-authorisation bond depends on the selected insurance, and it is up to $500 NZD.
                      <a
                        href="#"
                        click.prevent
                        v-b-modal.insurance
                      >(Click here for more details)</a>.
                    </li>
                    <li>The pre-authorisation bond will be released in 10-15 working days after you return the vehicle.</li>
                    <li>Additional Yong Driver fee will apply for $10 NZD per day if the driver’s age between 21 to 25.</li>
                    <li>All our rental vehicles NOT allow crossing the island. Please do NOT take our rental vehicle to South Island by ferry.</li>
                  </ol>
                </b-form-group>

                <b-form-group class="mb-0" v-else>
                  <b-col cols="12" class="h3 text-danger">重要提示</b-col>
                  <ol>
                    <li>当你办理登机手续时，司机信用卡下的预授权保证金将被冻结。</li>
                    <li>您的信用卡或借记卡必须有VISA或万事达标志（Mastercard）。</li>
                    <li>
                      预授权保证金的金额取决于所选择的保险，最高可达500新西兰元
                      <a href="#" click.prevent v-b-modal.insurance>(点击此处查看详情)。</a>.
                    </li>
                    <li>预授权保证金将在您归还车辆后10-15个工作日内释放。</li>
                    <li>如果司机的年龄在21岁到25岁之间，将额外收取每天10新西兰元的费用。</li>
                    <li>我们所有的租赁车辆都不允许登船跨越南北岛屿。请不要乘坐我们租赁的车辆前往南岛。</li>
                  </ol>
                </b-form-group>

                <div class="w-100 border-top my-2"></div>
                <b-form-group class="px-sm-3 mb-n2">
                  <div class="d-flex flex-flow justify-content-start">
                    <b-form-checkbox v-model="order.termsChecked" class="mr-1" required>{{ $t("Iagreeto") }}</b-form-checkbox>
                    <a href="#" click.prevent v-b-modal.modal-xl>{{ $t("TermsConditions") }}</a>.
                  </div>
                </b-form-group>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <b-col class="col-md-3 col-12 mt-4">
          <div class="sticky-top">
            <b-form class="bg-white">
              <h3 class="bg-warning text-white p-3 rounded">{{ $t("BookingSummary") }}</h3>

              <div class="px-3">
                <b-row no-gutters>
                  <b-col cols="3" class>
                    <i class="tt-icon cheyaochi h2"></i>
                  </b-col>
                  <b-col cols="9 ml-n2">
                    <b-row no-gutters>
                      <h5 class="text-info mb-1">{{ $t("PickUp") }}</h5>
                    </b-row>
                    <div>{{ order.pickUpLocation }}</div>
                    <div class="font-weight-bold">{{ `${order.pDate +' '+ order.pTime}` | dateTimeFormat }}</div>
                  </b-col>
                </b-row>
                <b-row no-gutters class="mt-3">
                  <b-col cols="3" class>
                    <i class="tt-icon chefanhui h2"></i>
                  </b-col>
                  <b-col cols="9  ml-n2">
                    <h5 class="text-info mb-1">{{ $t("Return") }}</h5>
                    <div>{{ order.dropOffLocation }}</div>
                    <div class="font-weight-bold">{{ `${order.rDate+' '+ order.rTime}` | dateTimeFormat }}</div>
                  </b-col>
                </b-row>
                <div class="border-top my-4"></div>
                <b-row>
                  <!-- <b-col cols="7">{{ order.model }}</b-col> -->
                  <b-col cols="7">{{ $t("CarRentalFee") }}</b-col>
                  <b-col cols="5" class="font-weight-bold text-right">${{ order.carTotalMoney | moneyLocaelString}}</b-col>
                </b-row>
                <b-row v-for="(option, idx) in order.options" :key="idx">
                  <b-col cols="7">
                    {{ $t(option.item) }}
                    <span v-if="!!option.qty && option.qty>1">&nbsp;x{{option.qty}}</span>
                  </b-col>
                  <b-col cols="5" class="font-weight-bold text-right">${{ option.price | moneyLocaelString}}</b-col>
                </b-row>
                <b-row align-v="center" v-if="!!order.discount && order.discount > 0">
                  <b-col cols="7">{{ $t("Discount") }}</b-col>
                  <b-col cols="5" class="font-weight-bold text-danger text-right">- ${{ order.discount | moneyLocaelString }}</b-col>
                </b-row>
                <div class="border-top my-4"></div>
                <b-row align-v="center" no-gutters>
                  <b-col class="text-dark h3">{{ $t("TOTAL") }}</b-col>
                  <b-col auto class="h3 font-weight-bold text-right">
                    ${{ order.totalPay | moneyLocaelString}}
                    <br />NZD
                  </b-col>
                </b-row>
                <b-row class="pb-2">
                  <b-col cols="auto" class="ml-auto">{{ $t("For") }} {{ order.totalDay }} {{ $t("DaysGSTincluded") }}</b-col>
                </b-row>
              </div>
            </b-form>
            <b-form class="bg-white mt-5">
              <b-row align-h="center">
                <i class="tt-icon safe-payment text-success h1 mt-2"></i>
              </b-row>
              <h2 class="text-center">{{ $t("Howtopay") }}</h2>
              <div class="px-4 pt-2" v-if="$i18n.locale === 'en'">
                No pre-payment needed when booking.
                <br />
                <br />Credit card/debit card must be in the name of driver.
                <br />
                <br />
                <b>Card only show "Cardholder" will NOT accept.</b>
                <br />
                <br />We
                <b>only</b> accept card with Visa/Mastercard logo.
              </div>

              <div class="px-4 pt-2" v-else>
                预订时无需预先付款。
                <br />
                <br />信用卡/借记卡必须以司机实名登记。
                <br />
                <br />我们只接受带有Visa/Mastercard标识的卡。
              </div>

              <b-row align-h="around" class="px-4 py-2">
                <b-img fluid src="@/assets/other/visa.png"></b-img>
                <b-img fluid src="@/assets/other/mastercard.png"></b-img>
                <b-img fluid src="@/assets/other/wechat-pay.png"></b-img>
                <b-img fluid src="@/assets/other/alipay.png"></b-img>
              </b-row>
            </b-form>
          </div>
        </b-col>
        <b-row no-gutters style="height: 4rem;" class="d-flex flex-row justify-content-between my-4 col-md-9 col-12">
          <b-button size="lg" @click="back">
            <b-row class="px-2" align-v="center">
              <b-col>
                <span class="d-inline-block" style="transform: rotate(180deg);">➔</span>
                {{ $t("Back") }}
              </b-col>
            </b-row>
          </b-button>
          <b-button size="lg" variant="success" @click="bookNow" :disabled="!this.order.termsChecked || cantRental">{{ $t("BookNow") }} ➔</b-button>
        </b-row>
      </b-row>
    </div>

    <b-modal id="insurance" size="xl" hide-footer title="Insurance Comparison">
      <b-card no-body>
        <b-table
          class="border-top mb-0"
          striped
          :hover="false"
          :fields="insuranceFields"
          :items="insuranceItems"
          thead-class="bg-light text-info text-center"
          tbody-tr-class="text-center"
          style="font-size:0.8rem;"
        >
          <template v-slot:cell(item)="data">
            <span class="text-left d-block pl-sm-2">{{ data.value }}</span>
          </template>
          <template v-slot:head(item)>
            <span class="text-left d-block pl-sm-2">{{ $t("InsuranceComparison") }}</span>
          </template>
        </b-table>
      </b-card>
    </b-modal>

    <b-modal id="modal-xl" size="xl" title="Terms & Conditions">
      <div>
        <h4>1. Vehicle description</h4>
        <p>The owner will let and the hirer will take to hire the motor vehicle described on this agreement.</p>
        <h4>2. Duration of hire</h4>
        <p>The term of the hire shall be for the period as described in this agreement. Any agreed extension to the hire shall be paid in full at the time of the extension and upon payment any such extension will form part of this agreement. The owner reserves the right to take possession of the vehicle if at any time the rental amount payable is outstanding, the cost of repossession of the vehicle and any costs incurred in recovering the outstanding amounts will be the responsibil­ ity of the hirer.</p>
        <h4>3. Person(s) who may drive the vehicle</h4>
        <p>
          The vehicle may be
          driven during the period of hire only by the persons described in this agreement and only if such person holds a full current driver’s license (particulars of which are given alongside his name and address) appropriate for the vehicle at the time when they are driving the vehicle
        </p>
        <h4>4. Payments by hirer</h4>
        <ol type="a">
          <li>The hirer shall pay to the owner the amount indicated as the Balance Due on the agreement at the commencement of the hire.</li>
          <li>
            In addition to the payment referred to in clause 4 (a) of this agreement the hirer shall pay for any of the following where applicable:
            <ol type="i">
              <li>All petrol or other fuel used</li>
              <li>Any additional charges as outlined in Clause 12 that may be applicable to this agreement</li>
              <li>Any damage excess where damage has occurred to the vehicle</li>
              <li>Any costs in relation to the uplifting of the vehicle due to non­ payment or breach of the terms of this contract</li>
            </ol>
          </li>
        </ol>
        <h4>5. Hirers obligations</h4>
        <div>
          The hirer shall ensure that:
          <ol type="a">
            <li>The water in the radiator and battery of the vehicle is maintained at the proper level</li>
            <li>The oil in the vehicle is maintained at the proper level</li>
            <li>The tyres are maintained at the proper pressure</li>
            <li>The hirer shall ensure that all reasonable care is taken in handling and parking the vehicle and that it is left securely locked when not in use.</li>
          </ol>
        </div>

        <h4>6. Return of the vehicle</h4>
        <ol type="a">
          <li>The hirer shall at or before the expiry of the term of hire deliver the vehicle to the owner’s place of business or the owner’s agent’s place of business, or obtain the owner’s consent to the continuation of hire.</li>
          <li>The owner shall have the right to terminate the hiring and take immediate possession of the vehicle if the hirer fails to comply with any of the terms of this agreement or if the vehicle is damaged. the termination of the hiring under the authority of this clause shall be without prejudice to the other rights of the owner and the rights of the hirer under this agreement or otherwise.</li>
        </ol>
        <h4>7. Owners obligations</h4>
        <ol>
          <li>The owner shall supply the vehicle in a safe and roadworthy condition</li>
          <li>
            he owner shall be responsible for all ordinary and extraordinary costs of running the vehicle during the term of the hire except
            to the extent that by the terms of this agreement those costs are payable by the hirer.
          </li>
        </ol>
        <p>
          <b>Note:</b> by virtue of clause 4 of this agreement, the cost of petrol and other fuel, but not oil, used during the term of the hire is the responsibility of the hirer.
        </p>
        <h4>8. Mechanical repairs and accidents</h4>
        <ol type="a">
          <li>If the vehicle is damaged or requires repair or salvage, whether because of an accident or breakdown, the hirer shall advise the owner of the full circumstances by telephone as soon as practicable.</li>
          <li>Authority of the owner except to the extent that the repairs or salvage are necessary to prevent further damage to the vehicle or other property.</li>
          <li>The hirer shall ensure that no person shall interfere with the distance recorder or speedometer or except in any emergency any part of the engine, transmission, and braking or suspension systems of the vehicle.</li>
          <li>Punctures, glass and headlights are at the hirer expense.</li>
        </ol>
        <h4>9. Use of the vehicle</h4>
        <div>
          Hirer shall not use or permit the vehicle to be used for the carriage of passengers for hire or reward unless the vehicle is hired with the knowledge of the owner for use in a passenger service licensed under the transport services licensing act 1989. The hirer shall not:
          <ol type="a">
            <li>Sublet or hire the vehicle to any other person</li>
            <li>Permit the vehicle to be operated outside his authority</li>
            <li>Operate the vehicle, or permit it to be operated in circumstances that constitute an offence by the driver against section 58 of the transport act 1962 (which relates to driving or attempting to drive with excess breath or blood alcohol or operate under the influence of any alcohol or drugs).</li>
            <li>Operate the vehicle under any of the exclusions detailed in clause 10</li>
            <li>Operate the vehicle or permit it to be operated to propel or tow any other vehicle.</li>
            <li>Operate the vehicle or permit it to be operated in breach of the transport act 1962, the traffic regulations 1976 or any other act, regulations or bylaws to road traffic.</li>
            <li>Operate the vehicle or permit it to be operated for the transport of more than the number of passengers or more than the weight of goods specified in the certificate of loading for the vehicle.</li>
            <li>Operate the vehicle or permit it to be operated for the transport of animals, domestic or otherwise.</li>
          </ol>
        </div>
        <h4>10. Insurance</h4>
        <div>
          Subject to the exclusions set out below, the hirer and any driver authorized to drive the vehicle is fully indemnified in respect of any liability he might have to the owner in respect of loss or damage to the vehicle and its accessories and spare parts and any consequential loss of revenue or other expenses of the owner including towing and salvage costs associated with the recovery of the vehicle and its accessories and spare parts. subject to the exclusions set out below, the hirer and any driver authorized
          to drive the vehicle are indemnified to the extent of $10,000,000 in respect of any liability he might have for damage to any property (including injury to any animal) belonging to any other person and arising out of the use of the vehicle.
          <ol type="a">
            <li>The driver of the vehicle is under the influence of alcohol or any drug that affects his ability to drive the vehicle.</li>
            <li>The vehicle is in an unsafe or non­roadworthy condition that arose during the course of the hire and that caused or contributed to the damage or loss, and the hirer or driver was aware or ought to have been aware of the unsafe or non­ roadworthy condition of the vehicle.</li>
            <li>The vehicle is operated in any race, speed test, rally or contest</li>
            <li>The interior of the vehicle is damaged or soiled by cigarette burns, cuts, spillage or foreign materials</li>
            <li>Damage caused to, or by, ski racks and tyre chains</li>
            <li>The hirer is not a body corporate or department of state and the vehicle is driven by any person not named in this agreement</li>
            <li>The vehicle is driven by any person who at the time when he drives the vehicle is disqualified from holding or has never held a driver’s license appropriate for that vehicle.</li>
            <li>The vehicle is willfully or recklessly damaged by the hirer or any other person named in this agreement or driving the vehicle under the authority of the hirer, or is lost as a result of the willful or reckless behavior of the hirer or any such person</li>
            <li>The vehicle is operated on any of the following: all ski field roads, ball hut access (mt cook), skippers canyon (queenstown), all beaches including ninety mile beach, coast rd to russell (northland), lake waikaremoana rd sh 38 (central north island) any track or thoroughfare including gravel roads that is not officially designated as a road.</li>
            <li>The vehicle is operated outside the term of hire or any agreed extension of that term.</li>
            <li>The vehicle is operated in breach of any of the terms and conditions of this contract.</li>
          </ol>It is agreed between the owner and the hirer that section 11 of the insurance law reform act 1977 shall apply with respect to the above exclusions as if this clause constituted a contract of insurance.
        </div>

        <h4>11. Replacement of the vehicle</h4>
        <p>In the event of an accident no refund or unused rental will be made. provision of a replacement vehicle and insurance cover will be at the owner’s discretion.</p>
        <h4>12. Additional charges</h4>
        <ol type="a">
          <li>Traffic and parking fines: administration charge nzd$30.00</li>
          <li>Toll gate fees: administration charge nzd$30.00</li>
          <li>Non-payment charges: $30.00 contact fee (phone or email contact),interest fees at current bank lending rates and debt recovery fees as charged by any nominated agency will be charged to the hirer in the event of non payment of the rental charges.</li>
          <li>Cleaning charges: any extra­ordinary cleaning be at the expense of the hirer, including but not limited to carpet cleaning, rust treatment, deodorizing, upholstery cleaning.</li>
          <li>Late charges: late charges are charged at $15 per hour.</li>
        </ol>
      </div>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import utility from "../utils/utility";
export default {
    components: {
        flatPickr,
    },
    data() {
        return {
            codeFocus: false,
            dateConfig: {
                altInput: true,
                altFormat: "d/m/Y",
                minDate: "1900-01-01",
                maxDate: new Date(),
            },
            insuranceFields: [
                { key: "item", label: "" },
                { key: "basic", label: "Basic" },
                { key: "plus", label: "Protection" },
                { key: "premium", label: "Premium" },
            ],
            insuranceFields_zh: [
                { key: "item", label: "" },
                { key: "basic", label: "基本险" },
                { key: "plus", label: "偷盗险" },
                { key: "premium", label: "全险" },
            ],
            insuranceItems: [
                {
                    item: "Insurance price",
                    basic: "$0 NZD/day",
                    plus: "$21 NZD/day",
                    premium: "$29 NZD/day",
                },
                {
                    item: "Rental Vehicle Damage Excess Fee",
                    basic: "$4,025 NZD",
                    plus: "$300 NZD",
                    premium: "$0 NZD",
                },

                {
                    item: "Pre-Authorization on hold (as bond)",
                    basic: "$500 NZD",
                    plus: "$300 NZD",
                    premium: "$100 NZD",
                },
                {
                    item: "Your Vehicle Theft Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "AA Roadside Assistance Callout Fee",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Window Screen & Glass Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Tyres Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
                {
                    item: "Third Party Vehicle /  Property Damage Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
            ],
            order: {
                model: "",
                carPrice: "",
                totalDay: "",
                pickUpTime: "",
                returnTime: "",
                totalPrice: "",
                paidFee: "",
                discount: "",
                options: [
                    // {
                    //   category: "insurance",
                    //   item: "Basic",
                    //   qty: "1",
                    //   price: "0",
                    //   discount: "0",
                    // },
                ],
                firstName: "",
                lastName: "",
                email: "",
                countryCode: "",
                tel: "",
                flightNumber: "",
                birthdate: "",
                termsChecked: false,
                secondDriver: {},
            },
            codeOptions: [
                { text: "New Zealand +64", value: "+64" },
                { text: "Australia +61", value: "+61" },
                { text: "United States +1", value: "+1" },
                { text: "United Kingdom +44", value: "+44" },
                { text: "Afghanistan +93", value: "+93" },
                { text: "Albania +355", value: "+355" },
                { text: "Algeria +213", value: "+213" },
                { text: "Andorra +376", value: "+376" },
                { text: "Angola +244", value: "+244" },
                { text: "Antigua and Barbuda +1268", value: "+1268" },
                { text: "Argentina +54", value: "+54" },
                { text: "Armenia +374", value: "+374" },
                { text: "Australia +61", value: "+61" },
                { text: "Austria +43", value: "+43" },
                { text: "Azerbaijan +994", value: "+994" },
                { text: "Bahamas +1242", value: "+1242" },
                { text: "Bahrain +973", value: "+973" },
                { text: "Bangladesh +880", value: "+880" },
                { text: "Barbados +1246", value: "+1246" },
                { text: "Belarus +375", value: "+375" },
                { text: "Belgium +32", value: "+32" },
                { text: "Belize +501", value: "+501" },
                { text: "Benin +229", value: "+229" },
                { text: "Bermuda +1441", value: "+1441" },
                { text: "Bhutan +975", value: "+975" },
                { text: "Bolivia +591", value: "+591" },
                { text: "Bosnia and Herzegovina +387", value: "+387" },
                { text: "Botswana +267", value: "+267" },
                { text: "Brazil +55", value: "+55" },
                { text: "Brunei +673", value: "+673" },
                { text: "Bulgaria +359", value: "+359" },
                { text: "Burkina Faso +226", value: "+226" },
                { text: "Burundi +257", value: "+257" },
                { text: "Cambodia +855", value: "+855" },
                { text: "Cameroon +237", value: "+237" },
                { text: "Canada +1", value: "+1" },
                { text: "Cape Verde +238", value: "+238" },
                { text: "Central African Republic +236", value: "+236" },
                { text: "Chad +235", value: "+235" },
                { text: "Chile +56", value: "+56" },
                { text: "China +86", value: "+86" },
                { text: "Colombia +57", value: "+57" },
                { text: "Comoros +269", value: "+269" },
                { text: "Congo +242", value: "+242" },
                { text: "Cook Islands +682", value: "+682" },
                { text: "Costa Rica +506", value: "+506" },
                { text: "Côte d'Ivoire +225", value: "+225" },
                { text: "Croatia +385", value: "+385" },
                { text: "Cuba +53", value: "+53" },
                { text: "Cyprus +357", value: "+357" },
                { text: "Czech Republic +420", value: "+420" },
                { text: "Denmark +45", value: "+45" },
                { text: "Djibouti +253", value: "+253" },
                { text: "Dominica +1767", value: "+1767" },
                { text: "Dominican Republic +1809", value: "+1809" },
                { text: "East Timor +670", value: "+670" },
                { text: "Ecuador +593", value: "+593" },
                { text: "Egypt +20", value: "+20" },
                { text: "El Salvador +503", value: "+503" },
                { text: "Equatorial Guinea +240", value: "+240" },
                { text: "Eritrea +291", value: "+291" },
                { text: "Estonia +372", value: "+372" },
                { text: "Ethiopia +251", value: "+251" },
                { text: "Fiji +679", value: "+679" },
                { text: "Finland +358", value: "+358" },
                { text: "France +33", value: "+33" },
                { text: "French Polynesia +689", value: "+689" },
                { text: "Gabon +241", value: "+241" },
                { text: "Gambia +220", value: "+220" },
                { text: "Georgia +995", value: "+995" },
                { text: "Germany +49", value: "+49" },
                { text: "Ghana +233", value: "+233" },
                { text: "Greece +30", value: "+30" },
                { text: "Grenada +1473", value: "+1473" },
                { text: "Guatemala +502", value: "+502" },
                { text: "Guinea +224", value: "+224" },
                { text: "Guinea-Bissau +245", value: "+245" },
                { text: "Guyana +592", value: "+592" },
                { text: "Haiti +509", value: "+509" },
                { text: "Honduras +504", value: "+504" },
                { text: "Hong Kong +852", value: "+852" },
                { text: "Hungary +36", value: "+36" },
                { text: "Iceland +354", value: "+354" },
                { text: "India +91", value: "+91" },
                { text: "Indonesia +62", value: "+62" },
                { text: "Iran +98", value: "+98" },
                { text: "Iraq +964", value: "+964" },
                { text: "Ireland +353", value: "+353" },
                { text: "Israel +972", value: "+972" },
                { text: "Italy +39", value: "+39" },
                { text: "Jamaica +1876", value: "+1876" },
                { text: "Japan +81", value: "+81" },
                { text: "Jersey +44", value: "+44" },
                { text: "Jordan +962", value: "+962" },
                { text: "Kazakhstan +7", value: "+7" },
                { text: "Kenya +254", value: "+254" },
                { text: "Kiribati +686", value: "+686" },
                { text: "Korea, North +850", value: "+850" },
                { text: "Korea, South +82", value: "+82" },
                { text: "Kuwait +965", value: "+965" },
                { text: "Kyrgyzstan +996", value: "+996" },
                { text: "Laos +856", value: "+856" },
                { text: "Latvia +371", value: "+371" },
                { text: "Lebanon +961", value: "+961" },
                { text: "Lesotho +266", value: "+266" },
                { text: "Liberia +231", value: "+231" },
                { text: "Libya +218", value: "+218" },
                { text: "Liechtenstein +423", value: "+423" },
                { text: "Lithuania +370", value: "+370" },
                { text: "Luxembourg +352", value: "+352" },
                { text: "Macau +853", value: "+853" },
                { text: "Macedonia +389", value: "+389" },
                { text: "Madagascar +261", value: "+261" },
                { text: "Malawi +265", value: "+265" },
                { text: "Malaysia +60", value: "+60" },
                { text: "Maldives +960", value: "+960" },
                { text: "Mali +223", value: "+223" },
                { text: "Malta +356", value: "+356" },
                { text: "Marshall Islands +692", value: "+692" },
                { text: "Mauritania +222", value: "+222" },
                { text: "Mauritius +230", value: "+230" },
                { text: "Mexico +52", value: "+52" },
                { text: "Micronesia +691", value: "+691" },
                { text: "Moldova +373", value: "+373" },
                { text: "Monaco +377", value: "+377" },
                { text: "Mongolia +976", value: "+976" },
                { text: "Morocco +212", value: "+212" },
                { text: "Mozambique +258", value: "+258" },
                { text: "Myanmar +95", value: "+95" },
                { text: "Namibia +264", value: "+264" },
                { text: "Nauru +674", value: "+674" },
                { text: "Nepal +977", value: "+977" },
                { text: "Netherlands +31", value: "+31" },
                { text: "New Caledonia +687", value: "+687" },
                { text: "New Zealand +64", value: "+64" },
                { text: "Nicaragua +505", value: "+505" },
                { text: "Niger +227", value: "+227" },
                { text: "Nigeria +234", value: "+234" },
                { text: "Niue +683", value: "+683" },
                { text: "Norway +47", value: "+47" },
                { text: "Oman +968", value: "+968" },
                { text: "Pakistan +92", value: "+92" },
                { text: "Palau +680", value: "+680" },
                { text: "Panama +507", value: "+507" },
                { text: "Papua New Guinea +675", value: "+675" },
                { text: "Paraguay +595", value: "+595" },
                { text: "Peru +51", value: "+51" },
                { text: "Philippines +63", value: "+63" },
                { text: "Poland +48", value: "+48" },
                { text: "Portugal +351", value: "+351" },
                { text: "Qatar +974", value: "+974" },
                { text: "Romania +40", value: "+40" },
                { text: "Russia +7", value: "+7" },
                { text: "Rwanda +250", value: "+250" },
                { text: "Saint Kitts and Nevis +1869", value: "+1869" },
                { text: "Saint Lucia +1758", value: "+1758" },
                { text: "Saint Vincent and The Grenadines +1784", value: "+1784" },
                { text: "Samoa +685", value: "+685" },
                { text: "San Marino +378", value: "+378" },
                { text: "Sao Tome and Principe +239", value: "+239" },
                { text: "Saudi Arabia +966", value: "+966" },
                { text: "Senegal +221", value: "+221" },
                { text: "Serbia and Montenegro +381", value: "+381" },
                { text: "Seychelles +248", value: "+248" },
                { text: "Sierra Leone +232", value: "+232" },
                { text: "Singapore +65", value: "+65" },
                { text: "Slovakia +421", value: "+421" },
                { text: "Slovenia +386", value: "+386" },
                { text: "Solomon Islands +677", value: "+677" },
                { text: "Somalia +252", value: "+252" },
                { text: "South Africa +27", value: "+27" },
                { text: "Spain +34", value: "+34" },
                { text: "Sri Lanka +94", value: "+94" },
                { text: "Sudan +249", value: "+249" },
                { text: "Suriname +597", value: "+597" },
                { text: "Swaziland +268", value: "+268" },
                { text: "Sweden +46", value: "+46" },
                { text: "Switzerland +41", value: "+41" },
                { text: "Syria +963", value: "+963" },
                { text: "Tahiti +689", value: "+689" },
                { text: "Taiwan +886", value: "+886" },
                { text: "Tajikistan +992", value: "+992" },
                { text: "Tanzania +255", value: "+255" },
                { text: "Thailand +66", value: "+66" },
                { text: "Togo +228", value: "+228" },
                { text: "Tonga +676", value: "+676" },
                { text: "Trinidad and Tobago +1868", value: "+1868" },
                { text: "Tunisia +216", value: "+216" },
                { text: "Turkey +90", value: "+90" },
                { text: "Turkmenistan +993", value: "+993" },
                { text: "Tuvalu +688", value: "+688" },
                { text: "Uganda +256", value: "+256" },
                { text: "Ukraine +380", value: "+380" },
                { text: "United Arab Emirates +971", value: "+971" },
                { text: "United Kingdom +44", value: "+44" },
                { text: "United States +1", value: "+1" },
                { text: "Uruguay +598", value: "+598" },
                { text: "Uzbekistan +998", value: "+998" },
                { text: "Vanuatu +678", value: "+678" },
                { text: "Vatican City +379", value: "+379" },
                { text: "Venezuela +58", value: "+58" },
                { text: "Vietnam +84", value: "+84" },
                { text: "Western Sahara +212", value: "+212" },
                { text: "Yemen +967", value: "+967" },
                { text: "Zambia +260", value: "+260" },
                { text: "Zimbabwe +263", value: "+263" },
            ],
            rentalExtraFee: { category: "YoungDriverFee", item: "Young Driver Fee", qty: 1, price: 10 },
        };
    },
    computed: {
        birthdateWarning() {
            let {
                birthdate,
                secondDriver: { birthdate: secondBirthDate },
                totalDay,
            } = this.order;
            let birthdateList = [birthdate, secondBirthDate];
            this.spliceOption(this.rentalExtraFee.category);
            let warningTypeList = [];
            birthdateList.forEach(tempBirthdate => {
                let warn = { desc: "", type: "" };
                if (!!tempBirthdate && this.$dayjs(tempBirthdate, ["YYYY-MM-DD", "DD/MM/YYYY"]).isValid()) {
                    let age = this.$dayjs(new Date()).diff(this.$dayjs(tempBirthdate), "year");
                    if (age < 21) {
                        warn = { desc: "The minimum driving age is 21 years old.", type: "danger" };
                    } else if (age < 25) {
                        warn = { desc: "Young driver surcharge of $10 NZD per day applied between age 21 to 25.", type: "warning" };
                    }
                }
                warningTypeList.push(warn);
            });
            let extraFeeLength = warningTypeList.filter(o => o.type === "warning").length;
            if (extraFeeLength) {
                let { price } = this.rentalExtraFee;
                this.spliceOption(this.rentalExtraFee.category, Object.assign({}, this.rentalExtraFee, { qty: extraFeeLength, price: extraFeeLength * price * totalDay }));
            }
            return warningTypeList;
        },
        cantRental() {
            let { birthdate } = this.order;
            let age = this.$dayjs(new Date()).diff(this.$dayjs(birthdate), "year");
            return age < 21;
        },
    },
    methods: {
        ...utility,
        spliceOption(catName, newItem = null) {
            let insIdx = -1;
            let has = this.order.options.some((opt, idx) => {
                if (opt.category === catName) {
                    insIdx = idx;
                    return true;
                }
            });
            if (!has) {
                insIdx = this.order.options.length;
            }
            let delCount = insIdx > -1 ? 1 : 0;
            if (!!newItem) {
                this.order.options.splice(insIdx, delCount, newItem);
            } else {
                this.order.options.splice(insIdx, delCount);
            }
            this.countSumPrice();
        },
        goto(route) {
            this.$router.push(route);
        },
        async getCarTotalMoney() {
            let error = this.dateRangeError(this.order);
            if (error) {
                this.$bvModal.msgBoxOk(error, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return;
            }
            let dateObj = this.dateRangeForMoney(this.order);
            let { carId } = this.order;
            let res = await this.$post("/api/getBookingCarById", Object.assign(dateObj, { carId: carId }));
            let { carAvgPrice, dateSum, carTotalMoney } = res.data;
            this.order = Object.assign(this.order, { carAvgPrice: carAvgPrice, totalDay: dateSum, carTotalMoney: carTotalMoney });
            this.countSumPrice();
        },
        countSumPrice() {
            let optionsPriceSum = this.order.options.map(o => o.price).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            let { carTotalMoney, rDate, rTime, pDate, pTime, discount } = this.order;
            this.order.totalPrice = parseFloat(carTotalMoney) + parseFloat(optionsPriceSum);
            this.order.totalPay = this.order.totalPrice - this.order.discount;
        },
        onContext({ hours, minutes }, field) {
            if (hours === null) {
                this.bookingForm[field] = "12:00";
            }
            if (hours < 8) {
                this.bookingForm[field] = "08:00";
            } else if (hours > 20 || (hours === 20 && minutes !== 0)) {
                this.bookingForm[field] = "20:00";
            }
        },
        back() {
            this.$router.go(-1);
        },
        async bookNow() {
            if (this.$refs.form.reportValidity() || this.cantRental) {
                try {
                    let { secondDriver, options } = this.order;
                    let language = localStorage.getItem("locale") || "en";
                    let res = await this.$post(
                        "/api/orderSubmit",
                        Object.assign({}, this.order, { secondDriver: JSON.stringify(secondDriver) }, { options: JSON.stringify(options) }, { language: language })
                    );
                    this.$router.push({ name: "orderReview", query: res.data });
                } catch (err) {
                    const h = this.$createElement;
                    let list = (err.data || err.message).split("<br>").map(o => h("p", { class: ["text-center font-weight-bold"] }, o));
                    this.$bvModal.msgBoxOk(list, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
    },
    watch: {
        "order.remark": {
            deep: true,
            handler: function (val) {
                if (!!val && val.length > 140) {
                    let valN = val.substring(0, 140);
                    console.log(valN == val);
                    console.log(valN.length);
                    this.$nextTick(() => {
                        this.order.remark = val.substring(0, 140);
                    });
                }
            },
        },
    },
    mounted() {
        window.scrollTo(0, 0);

        let initFormData = {
            firstName: "",
            lastName: "",
            email: "",
            countryCode: "",
            tel: "",
            flightNumber: "",
            birthdate: "",
            termsChecked: false,
            secondDriver: {},
            remark: "",
        };
        this.order = Object.assign(JSON.parse(sessionStorage.getItem("order")), initFormData);
        this.getCarTotalMoney();
    },
};
</script>

<style scoped>
.progress-font-top-bottom {
    top: 1rem;
}
.hoverpointer-insurance {
    color: #2ea9df;
}
.hoverpointer-insurance:hover {
    cursor: pointer;
    color: skyblue;
}
@media screen and (max-width: 568px) {
    .progress-font-top-bottom {
        top: 4rem;
    }
}
</style>
