<template>
  <div class="middle">
    <div>
      <b-img src="@/assets/other/hot-deals.jpg" class="rounded" fluid-grow alt="About us"></b-img>
    </div>
    <div class="about mx-auto">
      <p class="fredoka h1">
        {{ $t("HotDealswith") }}
        <span class="soft-blue">{{ $t("TopRental") }}</span>
      </p>
      <p>
        {{ $t("Bookyourrental") }}
        <span class="hover-universal" @click="goto('/contact',1)">{{ $t("Contactus") }}</span>
        {{ $t("Ifneedhelp") }}
      </p>
    </div>

    <div class="mx-lg-5 px-lg-5">
      <b-card class="border-0 deal" ref="deal-1">
        <b-row no-gutters>
          <b-col class="col-12 col-md-3 mt-md-4 mt-xg-0">
            <b-card-img src="@/assets/other/deal-one.jpg" img-alt="Hot deals on car rental" class="rounded-0"></b-card-img>
          </b-col>
          <b-col class="col-12 col-md-9">
            <b-card-body>
              <h2 class="fredoka">{{ $t("Newtous") }}?</h2>
              <b-card-text style="font-size:1.5rem">
                {{ $t("Warmwelcometo") }}
                <b class="soft-blue">NEW5</b>
                {{ $t("Forallvehicle") }}
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="border-0 deal" ref="deal-2">
        <b-row no-gutters>
          <b-col class="col-12 d-md-none d-block">
            <b-card-img src="@/assets/other/deal-two.jpg" img-alt="Best car rental deals" class="rounded-0"></b-card-img>
          </b-col>
          <b-col class="col-12 col-md-9">
            <b-card-body>
              <h2 class="fredoka">{{ $t("Rentforaweek") }}</h2>
              <b-card-text style="font-size:1.5rem">
                {{ $t("Usethepromo") }}
                <b class="soft-blue">WEEK10</b>
                {{ $t("Andget10") }}
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col class="col-12 col-md-3 mt-md-4 mt-xg-0 d-md-block d-none">
            <b-card-img src="@/assets/other/deal-two.jpg" img-alt="Best car rental deals" class="rounded-0"></b-card-img>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="border-0 deal" ref="deal-3">
        <b-row no-gutters>
          <b-col class="col-12 col-md-3 mt-md-4 mt-xg-0">
            <b-card-img src="@/assets/other/deal-three.jpg" img-alt="Best car rental deals" class="rounded-0"></b-card-img>
          </b-col>
          <b-col class="col-12 col-md-9">
            <b-card-body>
              <h2 class="fredoka">{{ $t("Rentforamonth") }}</h2>
              <b-card-text style="font-size:1.5rem">
                {{ $t("") }}
                <b class="soft-blue">LONG15</b>
                {{ $t("Andget15") }}
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="border-0 deal" ref="deal-4">
        <b-row no-gutters>
          <b-col class="col-12 col-md-3 mt-md-4 mt-xg-0 d-md-none d-block">
            <b-card-img src="@/assets/other/deal-four.png" img-alt="Best car rental deals" class="rounded-0"></b-card-img>
          </b-col>
          <b-col class="col-12 col-md-9">
            <b-card-body>
              <h2 class="fredoka">{{ $t("Forourlovely") }}</h2>
              <b-card-text style="font-size:1.5rem">{{ $t("Contactuswith") }}</b-card-text>
            </b-card-body>
          </b-col>
          <b-col class="col-12 col-md-3 mt-md-4 mt-xg-0 d-md-block d-none">
            <b-card-img src="@/assets/other/deal-four.png" img-alt="Best car rental deals" class="rounded-0"></b-card-img>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- <b-col cols="11" class="deal col-sm-5 mt-5">
          <b-card img-src="@/assets/other/cashback.jpg" img-alt="Image" img-top>
            <h2 class="fredoka">We expect your feedback.</h2>
            <b-card-text style="font-size:1.5rem">
              Leave your feedback on Google and send us the screenshot with vaild reservation number after returned the vehicles.
              We will review and process the cashback payment to you upto 7 working days.
              <a
                v-b-modal.cashback
                class="cashback-hover"
              >T&Cs</a>
              <b-modal id="cashback" hide-footer title="Terms & Conditions">
                <ol>
                  <li>Cashback reward is calculated on the base rental rate only, excludes insurance premiums.</li>
                  <li>By accepting the cashback, you will not be eligible for any other promotion in conjunction with your booking. We will refuse if other promotion code had been already used.</li>
                  <li>The cashback is not transferable and have to be into driver's account.</li>
                  <li>Top Rental reserves the right to modify, postpone or cancel the offer at any time.</li>
                </ol>
              </b-modal>
            </b-card-text>
          </b-card>
    </b-col>-->
  </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Car Rental Deals Auckland NZ| Top Rental ",
        meta: [
            {
                name: "Keywords",
                content: "car rental deals, rental car deals Auckland",
            },
            {
                name: "Description",
                content:
                    "Get hot deals on rental car in Auckland, NZ with Top Rental. Book your rental car by using the promotional code of each deal to enjoy the discounts. Book Now!",
            },
        ],
    },
    mounted() {
        let { pos } = this.$route.query;
        if (pos) {
            let deal = this.$refs["deal-" + pos];
            deal &&
                setTimeout(() => {
                    deal.scrollIntoView();
                }, 100);
        }
    },
    methods: {
        goto(route, pos) {
            this.$router.push({ path: route, query: { pos: pos } });
        },
    },
};
</script>

<style scoped>
.about {
    font-size: 1.2rem;
    width: 88%;
    text-align: center;
    margin-top: 2rem;
}
.deal {
    transition: 0.5s;
}
.deal:hover {
    transform: scale(1.05);
}
/* .cashback-hover {
    color: rgb(0, 183, 255);
}
.cashback-hover:hover {
    color: rgb(95, 209, 253);
} */
</style>