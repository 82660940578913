<template>
  <div class="middle bg-light" style="min-height: 70vh;">
    <b-row align-h="center">
      <b-col class="col-md-9 col-12">
        <b-progress class="mt-5 bg-gradient-light" max="100" show-value height="0.5rem">
          <b-progress-bar value="25" variant="success">
            <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">1</h5>
            <span class="text-dark position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Pickyourcar") }}</span>
          </b-progress-bar>
          <b-progress-bar value="25" variant="success">
            <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">2</h5>

            <span class="text-dark position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Insuranceextras") }}</span>
          </b-progress-bar>
          <b-progress-bar value="25" variant="success">
            <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">3</h5>

            <span class="text-dark position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Driversdetails") }}</span>
          </b-progress-bar>
          <b-progress-bar value="25" variant="success">
            <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">4</h5>
            <span class="text-dark position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Submit") }}</span>
          </b-progress-bar>
        </b-progress>
      </b-col>

      <b-col class="col-md-9 col-12 mt-5">
        <b-card no-body>
          <b-card-header class="bg-warning">
            <h2 class="text-center text-uppercase" style="color: #303960;">{{ $t("BookingReceived") }}</h2>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="6">
                <b-col style="line-height: 2rem; background-color: #ddf3f5;" class="p-3 rounded">
                  <b-col class="text-center">
                    <i class="tt-icon duigou1 h1 text-success"></i>
                  </b-col>

                  <p class="h3 mt-3">{{ $t("Yourbookinghasbeenreceived") }}</p>
                  <p class="text-black-50">{{ $t("Youwillreceive") }}</p>
                </b-col>
              </b-col>

              <b-col cols="12" md="6" class="p-2 pl-3">
                <b-row>
                  <b-col class="font-weight-bold">{{ $t("ReservationNumber") }}:</b-col>
                  <b-col style="color: #d92027;" class="font-weight-bold h5">{{ order.bkNbr }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">{{ $t("Name") }}:</b-col>
                  <b-col>{{ order.firstName }}&nbsp;{{ order.lastName }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">{{ $t("Email") }}:</b-col>
                  <b-col>{{ order.email }}</b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">{{ $t("Phone") }}:</b-col>
                  <b-col>{{ order.countryCode + " " + order.tel }}</b-col>
                </b-row>
                <b-row align-h="center" class="mt-5">
                  <b-button variant="info" size="lg" @click="$router.push('/')">{{ $t("BackHome") }}</b-button>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <h1 class="text-center d-none">
      <table>
        <thead>
          <tr>
            <td>項</td>
            <td>值</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Object.entries(order)" :key="item[0]">
            <td>{{ item[0] }}</td>
            <td>{{ item[1] }}</td>
          </tr>
        </tbody>
      </table>
    </h1>
  </div>
</template>

<script>
export default {
    data() {
        return {
            order: {},
        };
    },
    async mounted() {
        let query = this.$route.query;
        let res = await this.$post("/api/getOrder", query);
        this.order = res.data.order;
    },
};
</script>

<style>
.progress-font-top-bottom {
    top: 1rem;
}
@media screen and (max-width: 568px) {
    .progress-font-top-bottom {
        top: 4rem;
    }
}
</style>
