<template>
  <div class="middle">
    <div v-if="$i18n.locale === 'en'">
      <div class="text-center mt-3">
        <h1 class="fredoka">On your journey</h1>
        <div>
          <p>In an Emergency, please call 111 for Fire, Police and Ambulance.</p>
        </div>
      </div>
      <div class="mt-4">
        <h4 class="tips-heading">Breakdowns</h4>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="1" cols="2" class="pt-3 px-1">
              <b-card-img src="@/assets/mechanical.jpg" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-md-right">
              <div class="p-2">
                <h5>Mechanical</h5>All mechanical breakdowns which include engine faults, electrical faults, cooling faults are covered by Top Rental Roadside service program.
              </div>
            </b-col>

            <b-col md="1" cols="2" class="pt-3 px-1">
              <b-card-img src="@/assets/non-mechanical.jpg" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10">
              <div class="p-2">
                <h5>Non-Mechanical</h5>All non-mechanical breakdown will incur a call-out fee once diagnosed by AA mechnician. We can waive this call out fee if you purchased protection or premium insurance. Please check below for more details.
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand13 = !list.expand13">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand13 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How different is driving in New Zealand?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand13"
            class="answer"
          >Driving in New Zealand can be very different from your country. Our friendly staff are happy to provide safety driving instruction. We highly recommend you check the very useful information by clicking “Drive safe in New Zealand”</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand14 = !list.expand14">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand14 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do your rental cars all include unlimited miles?</span>
            </span>
          </b-row>
          <div v-show="list.expand14" class="answer">Yes, all cars have unlimited miles to use.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand15 = !list.expand15">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand15 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I smoke in the car?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand15"
            class="answer"
          >To ensure all our customer have a comfortable experience, we have ‘Smoke Free’ policy across all our rental vehicles. If you did not comply with the treaty, a minimum $400 NZD cleaning charge will be applied.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand16 = !list.expand16">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand16 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do you allow animals in your rental cars?</span>
            </span>
          </b-row>
          <div v-show="list.expand16" class="answer">No, Sorry, we are not allowed animals in the car.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand17 = !list.expand17">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand17 ? 'minus' : 'plus'"></i>
              <span>&nbsp; What do I do if I have an accident or breakdown?</span>
            </span>
          </b-row>
          <div v-show="list.expand17" class="answer">
            For accident, please call 111 for any emergency Fire, Police, and Ambulance.
            <br />For mechanical issues, please contact us on
            <a href="tel:0800 668 628">0800 668 628</a>
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand18 = !list.expand18">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand18 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Are there any roads I can’t drive on?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand18"
            class="answer"
          >You can drive almost any road except Ninety Mile Beach Road (Northland) and any roads north of Colville (Coromandel Peninsula).</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand19 = !list.expand19">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand19 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I take Interislander ferry to South Island?</span>
            </span>
          </b-row>
          <div v-show="list.expand19" class="answer">No, our vehicle is NOT allowed to drive in South Island. Please do not take ferry.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand20 = !list.expand20">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand20 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I extend my hire?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand20"
            class="answer"
          >If you’re looking to extend your hire, we strongly recommend that you contact us as soon as you can. Due to low availability in high season, we can’t guarantee any extension but we’ll do our best to accommodate your request.</div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="text-center mt-3">
        <h1 class="fredoka">旅途中</h1>
        <div>
          <p>在紧急情况下，请拨打111呼叫消防、警察和救护车。</p>
        </div>
      </div>
      <div class="mt-4">
        <h4 class="tips-heading">车辆故障</h4>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="1" cols="2" class="pt-3 px-1">
              <b-card-img src="@/assets/mechanical.jpg" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-md-right">
              <div class="p-2">
                <h5>机械故障</h5>所有机械故障，包括发动机故障，电气故障，冷却故障，都在Top Rental途行租车的责任范围内。
              </div>
            </b-col>

            <b-col md="1" cols="2" class="pt-3 px-1">
              <b-card-img src="@/assets/non-mechanical.jpg" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10">
              <div class="p-2">
                <h5>非机械故障</h5>经过AA机械师确认后,所有非机械故障所产生的道路救援费用，需要您自行承担。如果您购买了偷盗险或全险险，我们可以免除这一救援费用。请查看下面的详细信息。
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand13 = !list.expand13">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand13 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 在新西兰开车有什么不同?</span>
            </span>
          </b-row>
          <div v-show="list.expand13" class="answer">在新西兰开车和你的国家很不一样。我们友好的员工很乐意为您提供安全驾驶指导。我们强烈建议您点击“Drive safe in New Zealand”(在新西兰安全驾驶)查看非常有用的信息。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand14 = !list.expand14">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand14 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 你们租的车都不限制行驶里程吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand14" class="answer">是的，所有的汽车都有无限的行驶里程。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand15 = !list.expand15">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand15 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以在车里抽烟吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand15" class="answer">为了确保所有的客户都有舒适的体验，我们所有的租赁车辆都有“无烟”政策。如果您没有遵守条约，将会产生最低400新西兰元的清洁费。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand16 = !list.expand16">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand16 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 您出租的车辆里允许有动物吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand16" class="answer">不允许。不好意思，我们不允许任何宠物进车里。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand17 = !list.expand17">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand17 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 如果车辆发生事故或机械故障，我该怎么办?</span>
            </span>
          </b-row>
          <div v-show="list.expand17" class="answer">
            如发生紧急事件，请拨打111呼叫消防、警察和救护车。
            <br />对于任何车辆故障，请联系我们
            <a href="tel:0800 668 628">0800 668 628</a>
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand18 = !list.expand18">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand18 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 有哪些路段我不能前往吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand18" class="answer">除了北岛的九十里海滩（Ninety Mile Beach Road） 和 科罗曼德尔半岛的北部路段(Coromandel Peninsula)，你可以在所有正常路段行驶。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand19 = !list.expand19">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand19 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以搭乘轮渡前往南岛吗？</span>
            </span>
          </b-row>
          <div v-show="list.expand19" class="answer">抱歉不可以。我们的车辆不允许开往南岛，请不要登船跨岛。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand20 = !list.expand20">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand20 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我能否申请延期？</span>
            </span>
          </b-row>
          <div v-show="list.expand20" class="answer">如果您想延长租车时间，我们强烈建议您尽快与我们联系。由于旺季车辆供应可能会很紧张，我们不能保证一定成功延期，但我们会尽力满足您的要求。</div>
        </div>
      </div>
      <hr class="q-line" />
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: {
                expand13: false,
                expand14: false,
                expand15: false,
                expand16: false,
                expand17: false,
                expand18: false,
                expand19: false,
                expand20: false,
            },
        };
    },
};
</script>



<style scoped>
span > span {
    font-family: "Hind", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
}
</style>
