<template>
  <div class="middle">
    <b-overlay :show="showLoading" rounded>
      <b-table
        striped
        head-variant="dark"
        sticky-header="33rem"
        hover
        small
        responsive="sm"
        bordered
        :fields="fields"
        :items="provide"
        :per-page="perPage"
        :current-page="currentPage"
        style="min-height:36rem;"
        ref="table"
      >
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(tagName)="data">
          <b-button variant="link" @click="editTagPriceOpen(data.item.tagName,data.item.type)">{{data.item.tagName}}</b-button>
        </template>
        <template v-slot:cell(startDate)="data">{{ `${data.item.startDate}` | dateTimeFormat }}</template>
        <template v-slot:cell(endDate)="data">{{ `${data.item.endDate}` | dateTimeFormat }}</template>
        <template v-slot:cell(operation)="data">
          <b-row align-h="center" no-gutters>
            <b-button @click="deleteTag(data.item.tagName)" size="sm" variant="danger">Delete</b-button>
          </b-row>
        </template>
        <b-tr slot="custom-foot" class="text-right">
          <b-td colspan="11" class="text-right">
            <b-row align-h="end" align-v="start" no-gutters>
              <b-button size="sm" class="mr-3 pt-2" variant="success" v-b-modal.tagPriceModal>Add Price</b-button>
              <b-pagination :total-rows="rows" align="right" v-model="currentPage" :per-page="perPage" aria-controls="my-table"></b-pagination>
            </b-row>
          </b-td>
        </b-tr>
      </b-table>
    </b-overlay>
    <b-row align-h="end" no-gutters>
      <b-button size="lg" variant="info" @click="$router.go(-1)">Back</b-button>
    </b-row>

    <b-modal id="tagPriceModal" title="Add New Price Tag" size="lg" @show="tagPriceModalOpen(10)" no-close-on-backdrop>
      <b-form>
        <b-form-group id="tag" label="Tag" label-for="tag">
          <b-form-input id="tag" placeholder="Enter tag" required v-model="tagPrice.tagName"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Start Date:" label-for="input-2">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="tagPrice.startDate"
                locale="en-GB"
                required
                placeholder="Select Start Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.startTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group id="input-group-3" label="End Date:" label-for="input-3">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en-GB"
                v-model="tagPrice.endDate"
                required
                placeholder="Select End Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.endTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="7">
              <label class="h4">All Cars</label>
            </b-col>
            <b-col cols="3" class="text-center">
              <label class="h4">Price(NZD)</label>
            </b-col>
            <b-col>
              <label class="h4 text-center">Default</label>
            </b-col>
          </b-row>
          <b-row v-for="price in tagPrice.price" :key="price.carId" class="mt-2">
            <b-col cols="7">
              <label>{{price.modelName}}</label>
            </b-col>
            <b-col cols="3" class="text-center">
              <b-form-input type="number" v-model.number.trim="price.price"></b-form-input>
            </b-col>
            <b-col>
              <h4>
                <b-badge variant="danger" v-if="price.price === 0">Zero</b-badge>
                <b-badge variant="danger" v-else-if="price.price < 0">Negative</b-badge>
                <b-badge variant="danger" v-else-if="!price.price">NULL</b-badge>
                <b-badge variant="info" v-else-if="price.price == price.defaultPrice">default</b-badge>
                <b-badge variant="warning" v-else @click="price.price = price.defaultPrice" class="hoverpointer">{{price.defaultPrice}}</b-badge>
              </h4>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="info" @click="addTagPrice('tagPriceModal')">Add</b-button>
      </template>
    </b-modal>

    <b-modal id="editTagPriceModal" title="Edit Price Tag" size="lg" no-close-on-backdrop>
      <b-form>
        <b-form-group id="tag" label="Tag" label-for="tag">
          <b-form-input id="tag" placeholder="Enter tag" required v-model="tagPrice.tagName"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Start Date:" label-for="input-2">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="tagPrice.startDate"
                locale="en-GB"
                required
                placeholder="Select Start Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.startTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group id="input-group-3" label="End Date:" label-for="input-3">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en-GB"
                v-model="tagPrice.endDate"
                required
                placeholder="Select End Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.endTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="7">
              <label class="h4">All Cars</label>
            </b-col>
            <b-col cols="3" class="text-center">
              <label class="h4">Price(NZD)</label>
            </b-col>
            <b-col>
              <label class="h4">Default</label>
            </b-col>
          </b-row>
          <b-row v-for="price in tagPrice.price" :key="price.carId" class="mt-2">
            <b-col cols="7">
              <label>{{price.model}} {{price.modelYear}}</label>
            </b-col>
            <b-col cols="3">
              <b-form-input type="number" v-model.number.trim="price.price"></b-form-input>
            </b-col>
            <b-col>
              <h4>
                <b-badge variant="danger" v-if="price.price === 0">Zero</b-badge>
                <b-badge variant="danger" v-else-if="price.price < 0">Negative</b-badge>
                <b-badge variant="danger" v-else-if="!price.price">NULL</b-badge>
                <b-badge variant="info" v-else-if="price.price == price.defaultPrice">default</b-badge>
                <b-badge variant="warning" v-else @click="price.price = price.defaultPrice" class="hoverpointer">{{price.defaultPrice}}</b-badge>
              </h4>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="info" @click="editTagPrice('editTagPriceModal')">Save Edit</b-button>
      </template>
    </b-modal>

    <b-modal id="selloutModal" title="Add Stop Sale" size="lg" @show="tagPriceModalOpen(20)" no-close-on-backdrop>
      <b-form>
        <b-form-group id="tag" label="Tag" label-for="tag">
          <b-form-input id="tag" placeholder="Enter tag" required v-model="tagPrice.tagName"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Start Date:" label-for="input-2">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="tagPrice.startDate"
                locale="en-GB"
                required
                placeholder="Select Start Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.startTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group id="input-group-3" label="End Date:" label-for="input-3">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en-GB"
                v-model="tagPrice.endDate"
                required
                placeholder="Select End Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.endTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="7">
              <label class="h4">All Cars</label>
            </b-col>
            <b-col cols="5" class="text-center">
              <label class="h4">Sellout</label>
            </b-col>
          </b-row>
          <b-row v-for="price in tagPrice.price" :key="price.carId" class="mt-2">
            <b-col cols="7">
              <label>{{price.modelName}}</label>
            </b-col>
            <b-col cols="5" class="text-center">
              <b-form-checkbox type="number" v-model="price.price"></b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="info" @click="addTagPrice('selloutModal')">Add</b-button>
      </template>
    </b-modal>

    <b-modal id="editSelloutModal" title="Edit Stop Sale" size="lg" no-close-on-backdrop>
      <b-form>
        <b-form-group id="tag" label="Tag" label-for="tag">
          <b-form-input id="tag" placeholder="Enter tag" required v-model="tagPrice.tagName"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Start Date:" label-for="input-2">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="tagPrice.startDate"
                locale="en-GB"
                required
                placeholder="Select Start Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.startTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group id="input-group-3" label="End Date:" label-for="input-3">
          <b-row>
            <b-col>
              <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en-GB"
                v-model="tagPrice.endDate"
                required
                placeholder="Select End Date"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="tagPrice.endTime" required placeholder="Select Start Date"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="7">
              <label class="h4">All Cars</label>
            </b-col>
            <b-col cols="5" class="text-center">
              <label class="h4">Stop Sale</label>
            </b-col>
          </b-row>
          <b-row v-for="price in tagPrice.price" :key="price.carId" class="mt-2">
            <b-col cols="7">
              <label>{{price.modelName}}</label>
            </b-col>
            <b-col cols="5" class="text-center">
              <b-form-checkbox type="number" v-model="price.price"></b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="info" @click="editTagPrice('editSelloutModal')">Save Edit</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
let tomorrowDay = window.$dayjs(new Date()).add(1, "day").format("YYYY-MM-DD");

// 1.订单顺序
export default {
    data() {
        return {
            showLoading: false,
            perPage: 10,
            currentPage: 1,
            rows: 0,
            fields: [{ label: "", key: "index" }, "tagName", "startDate", "endDate", "size", "operation"],
            cars: [],
            tagPrice: {
                tagName: "",
                startDate: tomorrowDay,
                startTime: "00:00:00",
                endDate: this.$dayjs(new Date()).add(4, "day").format("YYYY-MM-DD"),
                endTime: "23:59:59",
                price: [],
            },
        };
    },
    methods: {
        async getCars() {
            let { data } = await this.$post("/api/getCars", { size: "all" });
            this.cars = data;
        },
        async getAllTag(currentPage, perPage) {
            let res = await this.$post("/getAllTagPrice", { currentPage: currentPage, perPage: perPage });
            return res.data;
        },
        async provide({ currentPage, perPage }) {
            this.showLoading = true;
            try {
                let { rows, list } = await this.getAllTag(currentPage, perPage);
                this.rows = rows;
                return list.map(o => Object.assign(o, { editPrice: false }));
            } finally {
                this.showLoading = false;
            }
        },
        refresh() {
            this.$refs.table.refresh();
        },
        tagPriceModalOpen(type) {
            let price = this.cars.map(car => {
                let siglePrice = type === 10 ? car.price : false;
                return {
                    carId: car.id,
                    price: siglePrice,
                    defaultPrice: siglePrice,
                    isDefault: true,
                    modelName: car.model + " " + car.modelYear,
                };
            });
            this.tagPrice = {
                tagName: "",
                startDate: tomorrowDay,
                startTime: "00:00:00",
                endDate: this.$dayjs(new Date()).add(4, "day").format("YYYY-MM-DD"),
                endTime: "23:59:59",
                type: type,
                price: price,
            };
        },
        tagPriceCheck() {
            let { tagName, startDate, startTime, endDate, endTime, type, price } = this.tagPrice;
            if (!tagName) {
                throw "Tag Name can't be null!";
            }

            if (!startDate || !startTime || !endDate || !endTime) {
                throw "Time can't be null!";
            }
            let filterPrice = type === 10 ? price.filter(o => !!o.price && (!!o.tagName || o.price != o.defaultPrice)) : price.filter(o => o.price != o.defaultPrice);
            if (filterPrice.length === 0) {
                throw "All value are empty or same to default!";
            }
            if (type === 10 && filterPrice.some(o => o.price <= 0)) {
                throw "Some value is negative!";
            }

            return [tagName, type, startDate + " " + startTime, endDate + " " + endTime, JSON.stringify(filterPrice)];
        },
        editTagPriceCheck() {
            let { tagName, startDate, startTime, endDate, endTime, type, price } = this.tagPrice;
            if (!tagName) {
                throw "Tag Name can't be null!";
            }

            if (!startDate || !startTime || !endDate || !endTime) {
                throw "Time can't be null!";
            }
            let filterPrice = price.filter(o => (!!o.price || type !== 10) && (!!o.id || o.price != o.defaultPrice));
            if (filterPrice.length === 0) {
                throw "All value are empty or same to default!";
            }
            if (type === 10 && filterPrice.some(o => o.price <= 0)) {
                throw "Some value is negative!";
            }

            return [tagName, type, startDate + " " + startTime, endDate + " " + endTime, JSON.stringify(filterPrice)];
        },
        async addTagPrice(modalId) {
            try {
                let [tagName, type, startDate, endDate, price] = this.tagPriceCheck();
                await this.$post("/addTagPrice", { tagName: tagName, type: type, startDate: startDate, endDate: endDate, price: price });
                this.refresh();
                this.$root.$emit("bv::hide::modal", modalId);
            } catch (err) {
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        async editTagPriceOpen(tag, type) {
            this.tagPrice = {};
            this.$root.$emit("bv::show::modal", type === 10 ? "editTagPriceModal" : "editSelloutModal");
            let { data } = await this.$post("/loadTagPrice", { tagName: tag });
            let price = data.map(o => {
                return Object.assign(o, {
                    modelName: o.model + o.modelYear,
                    price: type === 10 ? o.price : !!o.price && !!o.tagName,
                    defaultPrice: type === 10 ? o.defaultPrice : !!o.price && !!o.tagName,
                });
            });
            let one = data.filter(o => !!o.tagName)[0];
            let { tagName, startDate, endDate } = one;
            this.tagPrice = {
                tagName: tagName,
                type: type,
                startDate: startDate.substring(0, 10),
                startTime: startDate.substring(11, 19),
                endDate: endDate.substring(0, 10),
                endTime: endDate.substring(11, 19),
                price: price,
            };
        },
        async editTagPrice(modalId) {
            try {
                let [tagName, type, startDate, endDate, price] = this.editTagPriceCheck();
                await this.$post("/editTagPrice", { tagName: tagName, type: type, startDate: startDate, endDate: endDate, price: price });
                this.refresh();
                this.$root.$emit("bv::hide::modal", modalId);
            } catch (err) {
                console.log(err.data || err.message || err);
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        async deleteTag(tagName) {
            try {
                let result = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the tag price  [${tagName}]?`, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelVariant: "secondary",
                    cancelTitle: "NO",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                if (result) {
                    await this.$post("/deleteTagPrice", { tagName: tagName });
                    this.refresh();
                    this.$root.$emit("bv::hide::modal", "editTagPriceModal");
                }
            } catch (err) {
                console.log(err);
                this.$bvModal.msgBoxOk(err || err.data || err.message, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
    },
    mounted() {
        this.getCars();
    },
};
</script>