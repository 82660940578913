import { render, staticRenderFns } from "./onroad.vue?vue&type=template&id=3feda32f&scoped=true"
import script from "./onroad.vue?vue&type=script&lang=js"
export * from "./onroad.vue?vue&type=script&lang=js"
import style0 from "./onroad.vue?vue&type=style&index=0&id=3feda32f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3feda32f",
  null
  
)

export default component.exports