<template>
  <div class="middle">
    <div v-if="$i18n.locale === 'en'">
      <div class="text-center mt-3">
        <h1 class="fredoka">Drop off information</h1>
        <div>
          <p>All information about our fast and easy drop off process.</p>
        </div>
      </div>

      <div class="mt-4">
        <h4 class="tips-heading" v-if="$i18n.locale === 'en'">Where to drop off your car</h4>
        <b-card>
          <b-row class="px-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48134.59625564495!2d174.75313815757815!3d-36.97872736443537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4faf29468dd5%3A0x6cbece2b92d332e7!2sTop%20Rental!5e0!3m2!1sen!2snz!4v1600912514489!5m2!1sen!2snz"
              width="100%"
              height="400"
              frameborder="0"
              style="border:0;"
              allowfullscreen
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand21 = !list.expand21">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand21 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How can I drop-off my car?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand21"
            class="answer"
          >Please drop off your car in our Auckland Depot at 40 Ascot Road, Mangere, Auckland. We will arrange free shuttle bus to transfer you to airport terminal.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand22 = !list.expand22">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand22 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drop-off a vehicle outside business hours?</span>
            </span>
          </b-row>
          <div v-show="list.expand22" class="answer">
            Yes, but you need let us to know at least 1 day in advance before drop off your car.
            <br />
            <br />
            <b>Note:</b> There will be a surcharge of $50 NZD for outside business hours service fee.
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand23 = !list.expand23">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand23 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drop-off a car in somewhere else in New Zealand?</span>
            </span>
          </b-row>
          <div v-show="list.expand23" class="answer">
            No, you must drop off your car in our Auckland Depot in 40 Ascot Road, Mangere, Auckland.
            <br />
            <br />
            <b>Note:</b>
            Do Not drop off the car in Airport carpark.
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand24 = !list.expand24">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand24 ? 'minus' : 'plus'"></i>
              <span>&nbsp; If I return the car early, will I get a refund?</span>
            </span>
          </b-row>
          <div v-show="list.expand24" class="answer">Sorry, refund is not given for early returns. Please make your own travel schedule carefully.</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center mt-3">
        <h1 class="fredoka">还车信息</h1>
        <div>
          <p>关于我们快捷还车过程的所有信息。</p>
        </div>
      </div>

      <div class="mt-4">
        <h4 class="tips-heading" v-if="$i18n.locale === 'en'">还车地址</h4>
        <b-card>
          <b-row class="px-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48134.59625564495!2d174.75313815757815!3d-36.97872736443537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4faf29468dd5%3A0x6cbece2b92d332e7!2sTop%20Rental!5e0!3m2!1sen!2snz!4v1600912514489!5m2!1sen!2snz"
              width="100%"
              height="400"
              frameborder="0"
              style="border:0;"
              allowfullscreen
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand21 = !list.expand21">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand21 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我还车的流程是什么?</span>
            </span>
          </b-row>
          <div v-show="list.expand21" class="answer">请将您的车停在我们位于奥克兰机场附近的办公室 40 Ascot Road, Mangere, Auckland。我们将安排免费班车送您到机场航站楼。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand22 = !list.expand22">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand22 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以在营业时间以外还车吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand22" class="answer">
            可以，但您需要在还车前至少提前一天通知我们。
            <br />
            <br />
            <b>注意:</b>
            营业时间以外的服务会产生额外费用$50 NZD。
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand23 = !list.expand23">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand23 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以把车停在新西兰的其他地方吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand23" class="answer">
            不，您必须把车停在我们位于奥克兰机场附近的办公室 40 Ascot Road, Mangere, Auckland。
            <br />
            <br />
            <b>注意:</b>
            请勿将车辆直接停在机场停车场。
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand24 = !list.expand24">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand24 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 如果我提前还车，会得到退款吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand24" class="answer">抱歉，提前退货恕不退款。请仔细制定自己的旅行计划。</div>
        </div>
      </div>
      <hr class="q-line" />
    </div>
  </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Auckland Airport Drop Off - Car Rental | Top Rental",
        meta: [
            {
                name: "Keywords",
                content: "auckland airport drop off",
            },
            {
                name: "Description",
                content:
                    "We are a trusted vehicle rental company offering easy and convenient drop-off point right outside the domestic and international terminals of Auckland, NZ. Book Now!",
            },
        ],
    },
    data() {
        return {
            list: {
                expand20: false,
                expand21: false,
                expand22: false,
                expand23: false,
                expand24: false,
            },
        };
    },
};
</script>



<style scoped>
span > span {
    font-family: "Hind", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
}
</style>
