<template>
  <div class="middle">
    <b-overlay :show="showLoading" rounded>
      <b-table
        striped
        head-variant="dark"
        sticky-header="33rem"
        hover
        small
        responsive="sm"
        bordered
        :fields="fields"
        :items="provide"
        :per-page="perPage"
        :current-page="currentPage"
        style="min-height:40rem;"
        ref="table"
      >
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(model)="data">
          <span class="hoverpointer text-info font-weight-bold" v-b-modal.modalPopover @click="editCar(data.item)">
            {{ data.item.model }}
            <i class="tt-icon edit"></i>
          </span>
        </template>
        <template v-slot:cell(newModel)="data">{{ `${ data.item.newModel === '1' ? 'Y':'N'}` }}</template>
        <template v-slot:cell(size)="data">{{ data.item.size | carSize }}</template>
        <template v-slot:cell(price)="data">{{ `$ ${data.item.price}` }}</template>
        <template v-slot:cell(status)="data">
          <b-badge v-if="data.item.status===10" @click="stopSell(data.item.id,data.item.model)" class="hoverpointer" variant="success" style="width:5rem;">Active</b-badge>
          <b-badge v-else-if="data.item.status===98" @click="active(data.item.id,data.item.model)" class="hoverpointer" variant="danger" style="width:5rem;">Inactive</b-badge>
          <b-badge v-else variant="default" @click="active(data.item.id,data.item.model)" style="width:5rem;">Undefined</b-badge>
        </template>
        <b-tr slot="custom-foot" class="text-right">
          <b-td colspan="11" class="text-right">
            <b-pagination :total-rows="rows" align="right" v-model="currentPage" :per-page="perPage" aria-controls="my-table"></b-pagination>
          </b-td>
        </b-tr>
      </b-table>
    </b-overlay>
    <b-row align-h="end" no-gutters>
      <b-button size="lg" variant="info" @click="$router.go(-1)">Back</b-button>
    </b-row>
    <b-modal id="modalPopover" :title="eidtCarTitle">
      <b-form>
        <b-form-group id="input-group-1" label="Car Model:" label-for="input-1">
          <b-form-input id="input-1" trim v-model="car.model" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Model Year:" label-for="input-2">
          <b-form-input id="input-2" trim v-model="car.modelYear" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="New Model:" label-for="input-3">
          <b-form-select id="input-3" v-model="car.newModel" required :options="[{text:'Y',value:'1'},{text:'N',value:'0'}]"></b-form-select>
        </b-form-group>
        <b-form-group id="input-group-4" label="Price:" label-for="input-4">
          <b-form-input id="input-4" type="number" trim v-model="car.price" required></b-form-input>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="danger" @click="$bvModal.hide('modalPopover')">Cancel</b-button>
        <b-button variant="info" @click="submitEditCar">Submit</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// 1.订单顺序
export default {
    data() {
        return {
            showLoading: false,
            perPage: 10,
            currentPage: 1,
            rows: 0,
            fields: ["index", "model", "modelYear", "newModel", { key: "price", label: "Default Price" }, "size", "status"],
            car: {},
            eidtCarTitle: "",
        };
    },
    methods: {
        async getAllCar(currentPage, perPage) {
            let res = await this.$post("/api/getCars", { size: "all", currentPage: currentPage, perPage: perPage });
            return res.data;
        },
        async provide({ currentPage, perPage }) {
            this.showLoading = true;
            try {
                let data = await this.getAllCar(currentPage, perPage);
                this.rows = data.length;
                return data;
            } finally {
                this.showLoading = false;
            }
        },
        refresh() {
            this.$refs.table.refresh();
        },
        async active(id, model) {
            let res = await this.$bvModal.msgBoxConfirm(`Are you sure active car [ ${model} ]?`, {
                buttonSize: "sm",
                okVariant: "success",
                okTitle: "YES",
                centered: true,
                cancelTitle: "NO",
            });
            if (res) {
                try {
                    res = await this.$post("/activeCar", { id: id });
                    this.refresh();
                } catch (err) {
                    this.$bvModal.msgBoxOk(err.message, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
        async stopSell(id, model) {
            let res = await this.$bvModal.msgBoxConfirm(`Are you sure stop selling car [ ${model} ]?`, {
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                centered: true,
                cancelTitle: "NO",
            });
            if (res) {
                try {
                    res = await this.$post("/stopSell", { id: id });
                    this.refresh();
                } catch (err) {
                    this.$bvModal.msgBoxOk(err.message, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
        editCar(car) {
            this.car = JSON.parse(JSON.stringify(car));
            this.eidtCarTitle = `Edit Car [ ${car.model} ]`;
        },
        async submitEditCar() {
            try {
                let res = await this.$post("/editCar", this.car);
                this.$bvModal.hide("modalPopover");
                this.refresh();
            } catch (err) {
                const h = this.$createElement;
                let list = (err.data || err.message).split("<br>").map(o => h("p", { class: ["text-center font-weight-bold"] }, o));
                this.$bvModal.msgBoxOk(list, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
    },
    mounted() {},
};
</script>