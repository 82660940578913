<template>
  <div class="middle">
    <b-row class="mt-3" align-v="end" align-h="between" v-if="firstSearchSucc">
      <b-form-group class="col-10 offset-1 offset-sm-0 col-sm-5">
        <template slot="label">
          <span class="font-weight-bold">{{ $t("ReservationNumber") }}</span>
          <span class="text-danger">&nbsp;*</span>
        </template>
        <b-input v-model="bkNbr" trim size="lg" placeholder="Reservation Number"></b-input>
      </b-form-group>
      <b-form-group class="col-10 offset-1 offset-sm-0 col-sm-4">
        <template slot="label">
          <span class="font-weight-bold">{{ $t("LastName") }}</span>
          <span class="text-danger">&nbsp;*</span>
        </template>
        <b-input v-model="lastName" trim size="lg" placeholder="Last Name" @keyup.enter="getOrder"></b-input>
      </b-form-group>

      <b-form-group class="col-10 offset-1 offset-sm-0 col-sm-3 col-md-2">
        <b-button size="lg" class="col-12" variant="info" @click="getOrder">{{ $t("Search") }}</b-button>
      </b-form-group>
    </b-row>

    <b-row class="mt-5" align-h="center" align-v="center" style="min-height: 50vh;" v-else>
      <b-card no-body class="border-1 py-3 px-4 px-md-5 col-10 col-md-8">
        <h2 class="text-center mb-5">{{ $t("ManageYourBooking") }}</h2>
        <b-form-group>
          <template slot="label">
            <span class="font-weight-bold">{{ $t("ReservationNumber") }}</span>
            <span class="text-danger">&nbsp;*</span>
          </template>
          <b-input v-model="bkNbr" trim size="lg" class="my-3" placeholder="Reservation Number"></b-input>
        </b-form-group>
        <b-form-group>
          <template slot="label">
            <span class="font-weight-bold">{{ $t("LastName") }}</span>
            <span class="text-danger">&nbsp;*</span>
          </template>
          <b-input v-model="lastName" trim size="lg" class="my-3" placeholder="Last Name" @keyup.enter="getOrder"></b-input>
        </b-form-group>
        <b-button size="lg" class="my-3" variant="info" @click="getOrder">{{ $t("FindMyBooking") }}</b-button>
      </b-card>
    </b-row>

    <div v-if="!init">
      <b-row v-if="!error" align-h="between" no-gutters>
        <b-col cols="12" class="col-md-8">
          <b-card no-body>
            <b-card-header :class="orderStatus.titleBg">
              <h2 class="text-center text-uppercase">{{ $t("Booking") }} {{ $t(orderStatus.desc) }}</h2>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <b-col style="line-height: 2rem;" class="p-2 rounded" :class="orderStatus.infoBg">
                    <b-col class="text-center">
                      <i class="tt-icon h1" :class="orderStatus.icon"></i>
                    </b-col>

                    <p class="h3 mt-3">{{ $t("Yourbookinghas") }} {{ $t(orderStatus.desc) }} {{ $t("Contactusif") }}</p>
                    <p class="text-black-50">{{ $t("Yourdetailsare") }}</p>
                  </b-col>
                </b-col>

                <b-col cols="12" class="p-2 pl-3">
                  <b-row class="mt-2">
                    <b-col class="font-weight-bold">{{ $t("ReservationNumber") }}:</b-col>
                    <b-col style="color: #d92027;" class="font-weight-bold h5">{{ order.bkNbr }}</b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col class="font-weight-bold">{{ $t("Name") }}:</b-col>
                    <b-col>
                      <span class="text-capitalize">{{ order.firstName }}</span>
                      &nbsp;
                      <span class="text-capitalize">{{ order.lastName }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col class="font-weight-bold">{{ $t("DOB") }}:</b-col>
                    <b-col>{{ `${order.birthdate}` | dateFormat }}</b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col class="font-weight-bold">{{ $t("Email") }}:</b-col>
                    <b-col>{{ order.email }}</b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col class="font-weight-bold">{{ $t("Phone") }}:</b-col>
                    <b-col>{{ order.countryCode + " " + order.tel }}</b-col>
                  </b-row>
                  <b-row class="mt-2" v-if="!!order.flightNumber">
                    <b-col class="font-weight-bold">{{ $t("Flight") }}:</b-col>
                    <b-col>{{ order.flightNumber }}</b-col>
                  </b-row>
                  <b-row class="mt-1" v-if="!!order.remark">
                    <b-col class="font-weight-bold">{{ $t("Message") }}:</b-col>
                    <b-col>{{ order.remark }}</b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="border-top mt-3" no-gutters>
                <b-overlay :show="!car.photo" rounded="sm" class="rounded col-sm-7 col-12 border-sm-right">
                  <b-img-lazy fluid :src="car.photo || ''"></b-img-lazy>
                </b-overlay>
                <b-row class="col-12 col-sm-5 px-2" align-v="center" no-gutters>
                  <b-card-title class="text-dark col-12 border-bottom">
                    <b-row class="h1 text-info ml-1 car-title">{{ order.model }}</b-row>
                    <b-row class="h5 font-weight-light ml-1">{{ order.modelYear }}</b-row>
                  </b-card-title>
                  <b-row class="col-12">
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon passenger">{{ car.passengers }}</i>
                    </b-col>
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon cardoor">{{ car.doors }}</i>
                    </b-col>
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon luggage">{{ car.luggage }}</i>
                    </b-col>
                    <b-col class="col-5 col-sm-6 col-lg-3">
                      <i class="tt-icon engine">{{ car.engine }}</i>
                    </b-col>
                    <b-col auto>
                      <i class="tt-icon transmission">{{ car.transmission }}</i>
                    </b-col>
                  </b-row>
                  <b-row class="border-top col-12 ml-0" align-v="center">
                    <b-row class="h1 col-12" align-v="center">
                      ${{ order.carAvgPrice }}
                      <span class="h3 mb-0">{{ $t("Day") }}</span>
                    </b-row>
                    <b-row class="h5 col-12 mt-1" align-v="center">
                      <b-col class="col-sm-12 p-0 col-md-6 col-6">{{ $t("For") }} {{ order.totalDay }} {{ $t("Days") }}</b-col>

                      <b-col class="col-sm-12 col-md-6 col-6">NZD ${{ order.carTotalMoney }}</b-col>
                    </b-row>
                    <!-- <b-row class="h5 col-12 d-block d-sm-none" align-v="center" align-h="end"> For {{ order.totalDay }} days: NZD ${{ order.carPrice * order.totalDay }} </b-row> -->
                  </b-row>
                </b-row>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12" class="col-md-3">
          <div class="bg-white col-12 clo-md-10 ml-auto px-0 border">
            <h3 :class="orderStatus.titleBg" class="text-white p-3 rounded text-center text-md-left">{{ $t("BookingDetails") }}</h3>

            <div class="px-3">
              <!-- xs and sm is visible -->
              <b-row no-gutters class="d-flex d-md-none" align-h="between">
                <b-col cols="6">
                  <b-row>
                    <b-col cols="2">
                      <i class="tt-icon cheyaochi h2"></i>
                    </b-col>
                    <b-col cols="10">
                      <b-row no-gutters>
                        <h5 class="text-info mb-1">{{ $t("PickUp") }}</h5>
                      </b-row>
                      <div>{{ order.pickUpLocation }}</div>
                      <div class="font-weight-bold">{{ `${order.pickUpTime}` | dateTimeFormat }}</div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="2">
                      <i class="tt-icon chefanhui h2"></i>
                    </b-col>
                    <b-col cols="10">
                      <h5 class="text-info mb-1">{{ $t("Return") }}</h5>
                      <div>{{ order.dropOffLocation }}</div>
                      <div class="font-weight-bold">{{ `${order.dropOffTime}` | dateTimeFormat }}</div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- md and above is visible -->
              <b-row no-gutters class="d-none d-md-flex">
                <b-col cols="3">
                  <i class="tt-icon cheyaochi h2"></i>
                </b-col>
                <b-col cols="9 ml-n2">
                  <b-row no-gutters>
                    <h5 class="text-info mb-1">{{ $t("PickUp") }}</h5>
                  </b-row>
                  <div>{{ order.pickUpLocation }}</div>
                  <div class="font-weight-bold">{{ `${order.pickUpTime}` | dateTimeFormat }}</div>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-3 d-none d-md-flex">
                <b-col cols="3">
                  <i class="tt-icon chefanhui h2"></i>
                </b-col>
                <b-col cols="9 ml-n2">
                  <h5 class="text-info mb-1">{{ $t("Return") }}</h5>
                  <div>{{ order.dropOffLocation }}</div>
                  <div class="font-weight-bold">{{ `${order.dropOffTime}` | dateTimeFormat }}</div>
                </b-col>
              </b-row>

              <div class="border-top my-4"></div>
              <b-row>
                <b-col cols="7">{{ $t("CarRentalFee") }}</b-col>
                <b-col cols="5" class="font-weight-bold text-right">${{ order.carTotalMoney | moneyLocaelString}}</b-col>
              </b-row>
              <b-row v-for="(option, idx) in order.options" :key="idx">
                <b-col cols="7">
                  {{ $t(removeBlank(option.item)) }}
                  <span v-if="!!option.qty && option.qty>1">&nbsp;x{{option.qty}}</span>
                </b-col>
                <b-col cols="5" class="font-weight-bold text-right">${{ option.price | moneyLocaelString}}</b-col>
              </b-row>
              <b-row align-v="center" v-if="!!order.discount && order.discount > 0">
                <b-col cols="7">{{ $t("Discount") }}</b-col>
                <b-col cols="5" class="font-weight-bold text-danger text-right">- ${{ order.discount | moneyLocaelString }}</b-col>
              </b-row>
              <div class="border-top my-4"></div>
              <b-row align-v="center" no-gutters>
                <b-col class="text-dark h3">{{ $t("TOTAL") }}</b-col>
                <b-col auto class="h3 font-weight-bold text-right">
                  ${{ order.totalPay | moneyLocaelString}}
                  <br />NZD
                </b-col>
              </b-row>
              <b-row class="pb-2">
                <b-col cols="auto" class="ml-auto">{{ $t("For") }} {{ order.totalDay }} {{ $t("DaysGSTincluded") }}</b-col>
              </b-row>
            </div>
          </div>
          <b-row align-h="center" class="mt-5 col-10" v-if="order.status !== 30">
            <b-button variant="danger" size="lg" class="col-10" :disabled="isAdmin" @click="cancelOrder">{{ $t("CANCEL") }}</b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row aligh-h="center" class="mt-4">
        <b-col auto>
          <h3 class="h3 text-center">{{ error }}</h3>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import utility from "../utils/utility";
export default {
    data() {
        return {
            bkNbr: "",
            lastName: "",
            order: {
                bkNbr: "",
                lastName: "",
            },
            car: {},
            firstSearchSucc: true,
            init: true,
            error: "Result",
        };
    },
    methods: {
        ...utility,
        async getOrder({ bkNbr = this.bkNbr, lastName = this.lastName }) {
            this.bkNbr = bkNbr;
            this.lastName = lastName;
            if (!this.bkNbr) {
                if (this.$i18n.locale === "en") {
                    this.$bvToast.toast("The Reservation Number is required!", { variant: "danger", toaster: "b-toaster-top-center" });
                } else {
                    this.$bvToast.toast("预订号码不能为空!", { variant: "danger", toaster: "b-toaster-top-center" });
                }
                return;
            }
            if (!this.lastName) {
                if (this.$i18n.locale === "en") {
                    this.$bvToast.toast("The Last Name is required!", { variant: "danger", toaster: "b-toaster-top-center" });
                } else {
                    this.$bvToast.toast("姓氏不能为空!", { variant: "danger", toaster: "b-toaster-top-center" });
                }
                return;
            }
            this.order = {};
            this.car = {};
            this.error = "";
            try {
                let res = await this.$post("/api/getOrder", { bkNbr: this.bkNbr, lastName: this.lastName });
                let { order, car } = res.data;
                this.order = order;
                this.car = car;
                this.order.options = JSON.parse(order.options);
                this.firstSearchSucc = true;
            } catch (err) {
                console.error(err);
                this.error = err.data || "can't find";
            }
            this.init = false;
        },
        async cancelOrder() {
            try {
                let result = await this.$bvModal.msgBoxConfirm(`Are you sure you want to cancel the order ${this.order.bkNbr}?`, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelVariant: "secondary",
                    cancelTitle: "NO",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                if (result) {
                    let cancelResult = await this.$post("/api/cancelOrder", { bkNbr: this.order.bkNbr, lastName: this.order.lastName });
                    this.$bvToast.toast(cancelResult.data, { variant: "success", toaster: "b-toaster-top-center" });
                    this.getOrder(this.order);
                }
            } catch (err) {
                this.$bvToast.toast(err.data, { variant: "danger", toaster: "b-toaster-top-center" });
            }
        },
    },
    computed: {
        orderStatus() {
            if (!this.order.status) {
                return { desc: "submitted", titleBg: "bg-warning", infoBg: "info-bg-submitted", icon: "submit  text-dark" };
            }
            switch (this.order.status) {
                case 10:
                    return { desc: "submitted", titleBg: "bg-warning", infoBg: "info-bg-submitted", icon: "submit  text-dark" };
                case 20:
                    return { desc: "confirmed", titleBg: "bg-success  text-white", infoBg: "info-bg-confirmed", icon: "duigou1  text-success" };
                case 30:
                    return { desc: "canceled", titleBg: "title-canceled", infoBg: "info-bg-canceled", icon: "chahao  text-secondary" };
                default:
                    return { desc: "submitted", titleBg: "bg-warning", infoBg: "info-bg-submitted", icon: "submit  text-dark" };
            }
        },
        isAdmin() {
            return !!sessionStorage.getItem("token");
        },
    },
    mounted() {
        this.init = true;
        this.firstSearchSucc = false;
        let { bkNbr, lastName } = this.$route.query;
        if (!!bkNbr && !!lastName) {
            this.getOrder({ bkNbr: bkNbr, lastName: lastName });
        }
    },
};
</script>

<style scoped>
.title-canceled {
    background-color: #b7b7b7;
}
.info-bg-submitted {
    background-color: #ddf3f5;
}
.info-bg-confirmed {
    background-color: #cceabb;
}
.info-bg-canceled {
    background-color: #e8e8e8;
}
</style>
