<template>
    <div class="middle">
        <b-overlay :show="showLoading" rounded>
            <b-table striped head-variant="dark" hover small responsive="sm" bordered :fields="fields" :items="provide" style="min-height: 36rem" ref="table">
                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                <template v-slot:cell(dateRange)="data">
                    <b-button variant="link" @click="editDate(data.item.id)">
                        {{data.item.dateRange}}
                    </b-button>
                </template>
                <template v-slot:cell(id)="data">
                    <b-button variant="link" @click="deleteDate(data.item.dateRange,data.item.id)">
                        <i class="tt-icon hoverpointer cancel text-danger" size="sm" variant="danger"></i>
                    </b-button>
                </template>
            </b-table>
        </b-overlay>
        <b-row align-h="end" no-gutters>
            <b-button size="lg" class="mr-3 pt-2" variant="warning" v-b-modal.dateRangeModal>Add</b-button>
            <b-button size="lg" variant="info" @click="$router.go(-1)">Back</b-button>
        </b-row>
        <b-modal id="dateRangeModalEdit" title="Edit Shutdown Date Range" size="lg" no-close-on-backdrop @close="refresh()">
            <b-form>
                <b-form-group id="input-group-2" label="Start Date:" label-for="input-2">
                    <b-row>
                        <b-col>
                            <b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="shutdownDate.startDate" locale="en-GB" required placeholder="Select Start Date"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="shutdownDate.startTime" required placeholder="Select Start Time"></b-form-timepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group id="input-group-2" label="End Date: (must after start date)" label-for="input-2">
                    <b-row>
                        <b-col>
                            <b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="shutdownDate.endDate" locale="en-GB" required placeholder="Select End Date"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="shutdownDate.endTime" required placeholder="Select End Time"></b-form-timepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
            <template slot="modal-footer">
                <b-button variant="info" @click="saveDateRangeEdit()">Save</b-button>
            </template>
        </b-modal>
        <b-modal id="dateRangeModal" title="Add Shutdown Date Range" size="lg" @show="dateRangeModalOpen()" no-close-on-backdrop>
            <b-form>
                <b-form-group id="input-group-2" label="Start Date: (must after now)" label-for="input-2">
                    <b-row>
                        <b-col>
                            <b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="shutdownDate.startDate" locale="en-GB" required placeholder="Select Start Date"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="shutdownDate.startTime" required placeholder="Select Start Time"></b-form-timepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group id="input-group-2" label="End Date: (must after start date)" label-for="input-2">
                    <b-row>
                        <b-col>
                            <b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="shutdownDate.endDate" locale="en-GB" required placeholder="Select End Date"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="shutdownDate.endTime" required placeholder="Select End Time"></b-form-timepicker>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
            <template slot="modal-footer">
                <b-button variant="info" @click="saveDateRange()">Save</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import dayjs from "dayjs";
import utility from "../../utils/utility";
export default {
    data() {
        return {
            showLoading: false,
            fields: [
                { label: "", key: "index" },
                { label: "Date Range", key: "dateRange" },
                { label: "Remove", key: "id" },
            ],
            shutdownDate: {},
        };
    },
    methods: {
        ...utility,
        async getAllShutdownDate() {
            let { data } = await this.$post("/findAllShutdownDate", {});
            let list = data.map(o => Object.assign(o, { dateRange: o.startDate + " ~ " + o.endDate }));
            return list;
        },
        async provide({ currentPage, perPage }) {
            this.showLoading = true;
            try {
                let list = await this.getAllShutdownDate();
                return list;
            } finally {
                this.showLoading = false;
            }
        },
        refresh() {
            this.$refs.table.refresh();
        },
        async editDate(id) {
            let { data } = await this.$post("/findShutdownDate", { id: id });
            console.log(data);
            let { startDate, endDate, id: idOld } = data;

            let [startDateOld, startTimeOld] = startDate.split(" ");
            let [endDateOld, endTimeOld] = endDate.split(" ");

            this.shutdownDate = {
                id: idOld,
                startDate: startDateOld,
                startTime: startTimeOld,
                endDate: endDateOld,
                endTime: endTimeOld,
            };

            this.$root.$emit("bv::show::modal", "dateRangeModalEdit");
        },
        async saveDateRangeEdit() {
            try {
                let { startDate, startTime, endDate, endTime, id } = this.shutdownDate;
                if (!startDate || !startTime || !endDate || !endTime) {
                    throw { message: "Don't allow save empty date" };
                }
                let startDateComb = startDate + " " + startTime;
                let endDateComb = endDate + " " + endTime;
                if (
                    startDateComb === endDateComb ||
                    this.dateValid(startDateComb, endDateComb) ||
                    !this.dateAsUsual(startDateComb, endDateComb) ||
                    !this.dateAsUsual(new Date(), startDateComb)
                ) {
                    throw { message: "Invalid Date" };
                }
                await this.$post("/saveShutdownDate", { id:id, startDate: startDateComb, endDate: endDateComb });
                this.refresh();
                this.$root.$emit("bv::hide::modal", "dateRangeModalEdit");
            } catch (err) {
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        dateRangeModalOpen() {
            this.shutdownDate = { startTime: "12:00:00", endTime: "12:00:00" };
        },
        async saveDateRange() {
            try {
                let { startDate, startTime, endDate, endTime } = this.shutdownDate;
                if (!startDate || !startTime || !endDate || !endTime) {
                    throw { message: "Don't allow save empty date" };
                }
                let startDateComb = startDate + " " + startTime;
                let endDateComb = endDate + " " + endTime;
                if (
                    startDateComb === endDateComb ||
                    this.dateValid(startDateComb, endDateComb) ||
                    !this.dateAsUsual(startDateComb, endDateComb) ||
                    !this.dateAsUsual(new Date(), startDateComb)
                ) {
                    throw { message: "Invalid Date" };
                }
                await this.$post("/addShutdownDate", { startDate: startDateComb, endDate: endDateComb });
                this.refresh();
                this.$root.$emit("bv::hide::modal", "dateRangeModal");
            } catch (err) {
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        refresh() {
            this.$refs.table.refresh();
        },
        async deleteDate(dateRange, id) {
            try {
                let result = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the ${dateRange}?`, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelVariant: "secondary",
                    cancelTitle: "NO",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                if (result) {
                    await this.$post("/deleteDateRange", { id: id });
                    this.refresh();
                }
            } catch (err) {
                console.log(err);
                this.$bvModal.msgBoxOk(err || err.data || err.message, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
    },
    mounted() {},
};
</script>
<style>
</style>