<template>
  <div class="middle">
    <b-overlay :show="showLoading" rounded>
      <b-table striped head-variant="dark" hover small responsive="sm" bordered :fields="fields" :items="provide" style="min-height: 36rem" ref="table">
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(carModel)="data">
          {{
          data.item.model + " " + data.item.modelYear
          }}
        </template>
        <template v-slot:cell(dateRanges)="data">
          <b-button variant="link" v-if="data.item.dateRanges" class="hoverpointer" @click="openModal(data.item)" v-html="`<a>${data.item.dateRanges}<a/>`"></b-button>
          <b-button variant="link" style="float:left;margin-left:30%;" v-else @click="openModal(data.item)">
            <i class="tt-icon hoverpointer edit text-warning"></i>
          </b-button>
        </template>
        <template v-slot:cell(carId)="data">
          <b-button variant="link" @click=" data.item.dateRanges ? deleteDate(data.item):''">
            <i v-if="data.item.dateRanges" class="tt-icon hoverpointer cancel text-danger" size="sm" variant="danger"></i>
            <i v-else class="tt-icon cancel text-secondary" size="sm" variant="danger"></i>
          </b-button>
        </template>
      </b-table>
    </b-overlay>
    <b-row align-h="end" no-gutters>
      <b-button size="lg" variant="info" @click="$router.go(-1)">Back</b-button>
    </b-row>

    <b-modal id="soldOutModal" :title="soldOutData.carModel" size="lg" no-close-on-backdrop @close="refresh()">
      <b-form>
        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="5">
              <label>Start Date</label>
            </b-col>
            <b-col cols="4">
              <label>End Date</label>
            </b-col>
            <b-col class="text-center">
              <label>Operation</label>
            </b-col>
          </b-row>
        </b-form-group>
        <div v-for="(data,i) in soldOutData.dateRangeList" :key="data.startDate">
          <b-form-group>
            <b-row no-gutters>
              <b-col cols="2">
                <b-form-datepicker
                  size="sm"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="data.startDate"
                  locale="en-GB"
                  required
                  placeholder="Start Date"
                  :class="emptyBorder(data.startDate)"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="2">
                <b-form-timepicker
                  size="sm"
                  :hour12="false"
                  locale="en-GB"
                  v-model="data.startTime"
                  required
                  placeholder="Start Time"
                  :class="emptyBorder(data.startTime)"
                ></b-form-timepicker>
              </b-col>
              <b-col cols="2" offset="1">
                <b-form-datepicker
                  size="sm"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-GB"
                  v-model="data.endDate"
                  required
                  placeholder="End Date"
                  :class="emptyBorder(data.endDate)"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="2">
                <b-form-timepicker size="sm" :hour12="false" locale="en-GB" v-model="data.endTime" required placeholder="End Time" :class="emptyBorder(data.endTime)"></b-form-timepicker>
              </b-col>
              <b-col offset="1" class="pl-3">
                <i class="tt-icon h4 add hoverpointer fw-bold text-success" @click="soldOutData.dateRangeList.splice(i+1,0,{startTime:'00:00',endTime:'23:59'})"></i>
                <i
                  class="tt-icon h4 minus1 hoverpointer fw-bold text-danger ml-4"
                  v-if="soldOutData.dateRangeList.length>1"
                  @click="soldOutData.dateRangeList.splice(i,1)"
                ></i>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="warning" @click="sortDateRangeList">Sort</b-button>
        <b-button class="ml-3" variant="info" @click="saveDateRangeList">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import utility from "../../utils/utility";
export default {
    data() {
        return {
            showLoading: false,
            fields: [
                { label: "", key: "index" },
                { label: "Car Model", key: "carModel" },
                { label: "Stop Sale Date", key: "dateRanges" },
                { label: "Remove", key: "carId" },
            ],
            soldOutData: {},
        };
    },
    methods: {
        ...utility,
        async getAllCarsSoldOut() {
            let { data } = await this.$post("/findAllCarsSoldOut", {});
            let list = data.map(o => Object.assign(o, { editPrice: false }));
            return list;
        },
        async provide({ currentPage, perPage }) {
            this.showLoading = true;
            try {
                let list = await this.getAllCarsSoldOut();
                return list;
            } finally {
                this.showLoading = false;
            }
        },
        sortDateRangeList() {
            let list = this.soldOutData.dateRangeList;
            this.soldOutData.dateRangeList = [];
            this.soldOutData.dateRangeList = list.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        },
        async saveDateRangeList() {
            try {
                let { carId, dateRangeList } = this.soldOutData;
                if (dateRangeList.length === 0) {
                    throw { message: "Don't allow save empty date" };
                }
                let list = dateRangeList.map(({ startDate = "", startTime = "", endDate = "", endTime = "" }) => {
                    let startDateComb = startDate + " " + startTime;
                    let endDateComb = endDate + " " + endTime;
                    if (this.dateValid(startDateComb, endDateComb)) {
                        throw { message: "Invalid Date" };
                    }
                    return { carId: carId, startDate: startDateComb, endDate: endDateComb };
                });
                await this.$post("/saveSoldOutDateRangeList", { list: JSON.stringify(list) });
                this.refresh();
                this.$root.$emit("bv::hide::modal", "soldOutModal");
            } catch (err) {
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        async openModal({ carId, model, modelYear, dateRanges }) {
            let dateRangeList = (dateRanges &&
                dateRanges.split("<br/>").map(o => {
                    let [startDate, startTime, endDate, endTime] = o.split(" ~ ").flatMap(o => o.split(" "));
                    return { startDate: startDate, startTime: startTime, endDate: endDate, endTime: endTime };
                })) || [{ startTime: "00:00", endTime: "23:59" }];
            this.soldOutData = {
                carId: carId,
                carModel: model + " " + modelYear,
                dateRangeList: dateRangeList,
            };
            this.$root.$emit("bv::show::modal", "soldOutModal");
        },
        refresh() {
            this.$refs.table.refresh();
        },
        async deleteDate({ carId, model, modelYear }) {
            try {
                let result = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the ${model + " " + modelYear}'s all settings?`, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelVariant: "secondary",
                    cancelTitle: "NO",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                if (result) {
                    await this.$post("/deleteSoldOutDateRangeList", { carId: carId });
                    this.refresh();
                    this.$root.$emit("bv::hide::modal", "soldOutModal");
                }
            } catch (err) {
                console.log(err);
                this.$bvModal.msgBoxOk(err || err.data || err.message, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
        emptyBorder(dt) {
            if (!dt) {
                return "empty-danger-border";
            }
        },
    },
    mounted() {},
};
</script>
<style>
.empty-danger-border {
    border: 3px dashed #e83015;
}
</style>