<template>
  <div class="middle">
    <div class="m-0" v-if="$i18n.locale === 'en'">
      <h1 class="fredoka display-4 text-center mt-5">Terms & Conditions</h1>
      <p
        style="font-size:1.5rem"
        class="text-center mb-5"
      >An agreement made between TT Group Limited (hereinafter called the owner) and the hirer whose particulars are entered in this agreement; it is hereby agreed as follows:</p>
    </div>
    <div class="m-0" v-else>
      <h1 class="fredoka display-4 text-center mt-5">规则条例</h1>
      <p
        style="font-size:1.5rem"
        class="text-center mb-5"
      >An agreement made between TT Group Limited (hereinafter called the owner) and the hirer whose particulars are entered in this agreement; it is hereby agreed as follows:</p>
    </div>

    <div class="clauses px-3" v-if="$i18n.locale === 'en'">
      <h4>1. Vehicle description</h4>
      <p>The owner will let and the hirer will take to hire the motor vehicle described on this agreement.</p>
      <h4>2. Duration of hire</h4>
      <p>The term of the hire shall be for the period as described in this agreement. Any agreed extension to the hire shall be paid in full at the time of the extension and upon payment any such extension will form part of this agreement. The owner reserves the right to take possession of the vehicle if at any time the rental amount payable is outstanding, the cost of repossession of the vehicle and any costs incurred in recovering the outstanding amounts will be the responsibil­ ity of the hirer.</p>
      <h4>3. Person(s) who may drive the vehicle</h4>
      <p>The vehicle may be driven during the period of hire only by the persons described in this agreement and only if such person holds a full current driver’s license (particulars of which are given alongside his name and address) appropriate for the vehicle at the time when they are driving the vehicle.</p>
      <h4>4. Payments by hirer</h4>
      <ol type="a">
        <li>The hirer shall pay to the owner the amount indicated as the Balance Due on the agreement at the commencement of the hire.</li>
        <li>
          In addition to the payment referred to in clause 4 (a) of this agreement the hirer shall pay for any of the following where applicable:
          <ol type="i">
            <li>All petrol or other fuel used</li>
            <li>Any additional charges as outlined in Clause 12 that may be applicable to this agreement</li>
            <li>Any damage excess where damage has occurred to the vehicle</li>
            <li>
              Any costs in relation with vehicle damage, including but not
              limited to administration fee, lose of use fee, depreciation fee.
            </li>
          </ol>
        </li>
      </ol>
      <h4>5. Hirers obligations</h4>
      <div>
        The hirer shall ensure that:
        <ol type="a">
          <li>The water in the radiator and battery of the vehicle is maintained at the proper level</li>
          <li>The oil in the vehicle is maintained at the proper level</li>
          <li>The tyres are maintained at the proper pressure</li>
          <li>The hirer shall ensure that all reasonable care is taken in handling and parking the vehicle and that it is left securely locked when not in use.</li>
        </ol>
      </div>

      <h4>6. Return of the vehicle</h4>
      <ol type="a">
        <li>The hirer shall at or before the expiry of the term of hire deliver the vehicle to the owner’s place of business or the owner’s agent’s place of business, or obtain the owner’s consent to the continuation of hire.</li>
        <li>The owner shall have the right to terminate the hiring and take immediate possession of the vehicle if the hirer fails to comply with any of the terms of this agreement or if the vehicle is damaged. The termination of the hiring under the authority of this clause shall be without prejudice to the other rights of the owner and the rights of the hirer under this agreement or otherwise.</li>
      </ol>
      <h4>7. Owners obligations</h4>
      <ol type="a">
        <li>The owner shall supply the vehicle in a safe and roadworthy condition</li>
        <li>
          he owner shall be responsible for all ordinary and extraordinary costs of running the vehicle during the term of the hire except
          to the extent that by the terms of this agreement those costs are payable by the hirer.
        </li>
      </ol>
      <p>
        <b>Note:</b> By virtue of clause 4 of this agreement, the cost of petrol and other fuel, but not oil, used during the term of the hire is the responsibility of the hirer.
      </p>
      <h4>8. Mechanical repairs and accidents</h4>
      <ol type="a">
        <li>If the vehicle is damaged or requires repair or salvage, whether because of an accident or breakdown, the hirer shall advise the owner of the full circumstances by telephone as soon as practicable.</li>
        <li>Authority of the owner except to the extent that the repairs or salvage are necessary to prevent further damage to the vehicle or other property.</li>
        <li>The hirer shall ensure that no person shall interfere with the distance recorder or speedometer or except in any emergency any part of the engine, transmission, and braking or suspension systems of the vehicle.</li>
        <li>Punctures, glass and headlights are at the hirer expense.</li>
      </ol>
      <h4>9. Use of the vehicle</h4>
      <div>
        The hirer shall not use or permit the vehicle
        to be used for the carriage of passengers for hire or reward unless the vehicle is hired
        with the knowledge of the owner for use in a passenger service licensed under the
        Transport Services Licensing Act 1989.
        <br />The hirer shall not:
        <ol type="a">
          <li>Sublet or hire the vehicle to any other person</li>
          <li>Permit the vehicle to be operated outside his authority</li>
          <li>
            Operate the vehicle or permit it to be operated in circumstances that constitute
            an offence by the driver against Section 58 of the Transport Act 1962 (which
            relates to driving or attempting to drive with excess breath or blood alcohol or
            operate under the influence of any alcohol or drugs).
          </li>
          <li>Operate the vehicle under any of the exclusions detailed in Clause 10</li>
          <li>Operate the vehicle or permit it to be operated to propel or tow any other vehicle.</li>
          <li>
            Operate the vehicle or permit it to be operated in breach of the Transport Act
            1962, the Traffic Regulations 1976 or any other Act, regulations or bylaws to
            RoadTraffic
          </li>
          <li>Operate the vehicle or permit it to be operated for the transport of more than the number of passengers or more than the weight of goods specified in the certificate of loading for the vehicle.</li>
          <li>Operate the vehicle or permit it to be operated for the transport of animals, domestic or otherwise.</li>
        </ol>
      </div>
      <h4>10. Insurance</h4>
      <div>
        Subject to the exclusions set out below, the hirer and any driver authorized to drive the vehicle is fully indemnified in respect of any liability he might have to the owner in respect of loss or damage to the vehicle and its accessories and spare parts and any consequential loss of revenue or other expenses of the owner including towing and salvage costs associated with the recovery of the vehicle and its accessories and spare parts. Subject to the exclusions set out below, the hirer and any driver authorized
        to drive the vehicle are indemnified to the extent of $10,000,000 in respect of any liability he might have for damage to any property (including injury to any animal) belonging to any other person and arising out of the use of the vehicle.
        <ol type="a">
          <li>The driver of the vehicle is under the influence of alcohol or any drug that affects his ability to drive the vehicle.</li>
          <li>The vehicle is in an unsafe or non­roadworthy condition that arose during the course of the hire and that caused or contributed to the damage or loss, and the hirer or driver was aware or ought to have been aware of the unsafe or non­ roadworthy condition of the vehicle.</li>
          <li>The vehicle is operated in any race, speed test, rally or contest</li>
          <li>The interior of the vehicle is damaged or soiled by cigarette burns, cuts, spillage or foreign materials</li>
          <li>Damage caused to, or by, Ski Racks and TyreChains</li>
          <li>The hirer is not a body corporate or department of state and the vehicle is driven by any person not named in this agreement</li>
          <li>The vehicle is driven by any person who at the time when he drives the vehicle is disqualified from holding or has never held a driver’s license appropriate for that vehicle.</li>
          <li>The vehicle is willfully or recklessly damaged by the hirer or any other person named in this agreement or driving the vehicle under the authority of the hirer, or is lost as a result of the willful or reckless behavior of the hirer or any such person</li>
          <li>
            The vehicle is operated on any of the following: All ski field roads, Ball Hut
            access (Mt Cook), Skippers Canyon (Queenstown), all beaches including Ninety
            Mile Beach, Coast Rd to Russell (Northland), Lake Waikaremoana Rd SH 38
            (Central North Island) any track or thoroughfare including gravel roads that is
            not officially designated as a road
          </li>
          <li>The vehicle is operated outside the term of hire or any agreed extension of that term.</li>
          <li>The vehicle is operated in breach of any of the terms and conditions of this contract.</li>
        </ol>It is agreed between the owner and the hirer that Section 11 of the Insurance Law
        Reform Act 1977 shall apply with respect to the above exclusions as if this clause
        constituted a contract of insurance
      </div>

      <h4>11. Replacement of the vehicle</h4>
      <p>In the event of an accident no refund or unused rental will be made. Provision of a replacement vehicle and insurance cover will be at the owner’s discretion.</p>
      <h4>12. Additional charges</h4>
      <ol type="a">
        <li>Administration fee for traffic fines, parking fines, toll road fines: NZD $30.00</li>
        <li>Administration fee for Vehicle damage: NZD $50.00</li>
        <li>
          Non-Payment Charges: NZD $30.00 contact fee (phone or email),
          interest fees at current bank lending rates and debt recovery fees as charged by
          any nominated agency will be charged to the hirer in the event of non payment
          of the rental charges.
        </li>
        <li>
          Lose of use fee applied base on daily rates of current agreement times days
          between date of returned the vehicle and date of repair job done.
        </li>
        <li>Late Charges: Late charges are charged at $15 perhour.</li>
        <li>All listed surcharge in CLAUSE 12 are GST excluded.</li>
      </ol>
    </div>
    <div class="clauses px-3" v-else></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.clauses > h4 {
    font-family: "Fredoka One", cursive;
}
.clauses > p,
.clauses > div {
    margin-left: 1.5rem;
}
.clauses > ol {
    margin-left: 0.5rem;
}
</style>
