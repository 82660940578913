<template>
  <div class="middle">
    <b-card no-body class="text-center">
      <div class="bg-secondary text-light text-capitalize">{{ size }} cars</div>

      <div class="d-flex flex-row flex-wrap">
        <div class="col-lg-6 px-0 px-lg-3" v-for="car in cars" :key="car.id">
          <b-card no-body class="my-2">
            <b-card-title class="text-dark mb-0">
              <b-row class="pl-4 car-title p-0 h1">
                {{ $t(removeBlank(car.model)) }}
                <b-img src="@/assets/new.jpg" fluid class="position-absolute new-img" v-if="parseInt(car.newModel)"></b-img>
              </b-row>
              <b-row class="pl-4 car-model">{{ car.modelYear }}</b-row>
              <!-- <i class="h1 float-right tt-icon new text-warning mt-n1" v-if="car.price % 2"></i> -->
            </b-card-title>
            <b-row class="bg-light-secondary text-white mt-2" align-h="around" no-gutters>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class="bg-light-secondary">
                <i class="tt-icon passenger">{{ car.passengers }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon cardoor">{{ car.doors }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon luggage">{{ car.luggage }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class="ml-1 ml-lg-0">
                <i class="tt-icon engine">{{ car.engine }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon transmission">{{ $t(removeBlank(car.transmission)) }}</i>
              </b-col>
            </b-row>
            <b-row no-gutters class="grey-gradient car-height pb-2">
              <b-card-img :src="car.photo" :alt="'Hire ' + car.model" left class="col-lg-8" style="[;height:100%;]"></b-card-img>
              <div class="col-lg-4 text-lg-left text-center pt-2 pt-lg-4">
                <b-button
                  v-if="car.status==10"
                  :id="'tooltip-target-car-' + car.id"
                  variant="info"
                  size="lg"
                  class="shadow col-10 mt-5"
                  @click="selectCar(car)"
                >{{ $t("Select") }}</b-button>
                <b-button v-else variant="secondary" size="lg" class="shadow col-10 mt-5">{{ $t("SoldOut") }}</b-button>
              </div>
            </b-row>
            <b-row class="d-flex mt-sm-1 py-2 py-lg-3 border-top no-gutters">
              <span class="col-md-3 col-sm-5 col-lg-5 offset-1 text-left" v-for="(conf, i) in (car.configure || '').split(',')" :key="i">
                <i class="tt-icon duigou text-warning"></i>
                {{ $t(removeBlank(conf)) }}
              </span>
            </b-row>
          </b-card>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import utility from "../utils/utility";
export default {
    metaInfo: {
        title: "Hybrid and Toyota RAV4 Rental Car | Vehicle Rental Auckland NZ",
        meta: [
            {
                name: "Keywords",
                content: "vehicle rental auckland,hybrid car rental nz,hybrid car rental,toyota rav4 rental car",
            },
            {
                name: "Description",
                content:
                    "Rent a hybrid, Toyota RAV4, & Toyota Camry car etc. in Auckland, NZ at affordable prices with excellent and sustainable car rental services. Book Your Car Now!",
            },
        ],
    },
    data() {
        return {
            cars: [],
        };
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getCars();
    },
    methods: {
        ...utility,
        async getCars() {
            let res = await this.$post("/api/getCars", { size: this.$route.params.size });
            this.cars = res.data;
        },
        selectCar(car) {
            sessionStorage.setItem("order", JSON.stringify({ carId: car.id, pickUpLocation: "Auckland Airport", dropOffLocation: "Auckland Airport" }));
            this.$router.push("/options");
        },
    },
    computed: {
        size() {
            return this.$route.path.split("/")[2];
        },
        clientWidth() {
            return document.querySelector("html").clientWidth;
        },

        minScreen() {
            return this.clientWidth < 1280;
        },
    },
    mounted() {
        this.getCars();
    },
};
</script>

<style scoped>
.new-img {
    width: 150px;
    right: 25px;
    top: 20px;
}
.bg-light-secondary {
    background: #626365 linear-gradient(180deg, #8d8f92, #818182) repeat-x;
    border-color: #dee2e6;
}
@media screen and (min-width: 1280px) {
    .car-height {
        height: 15rem;
    }
}
@media screen and (min-width: 992px) and (max-width: 1118px) {
    .new-img {
        width: 100px;
        right: 20px;
        top: 42px;
    }
}
@media screen and (max-width: 528px) {
    .new-img {
        width: 100px;
        right: 5px;
        top: 32px;
    }
}
</style>
