<template>
  <div class="middle">
    <hr class="line" />
    <div class="hot-title">
      <h1 class="tt-font">Drive Safe In New Zealand</h1>
      <div>
        <p class="hot-words">
          There are a few things to know before hitting the kiwi roads with one of our cars. Because we care about you and your safety, we want to make sure your have a safe and unforgettable journey
          in New Zealand. Please take a moment to watch the video below.
        </p>
      </div>

      <!-- test zone for emails -->
      <div class="text-left ml-5"></div>
    </div>

    <div class="video-driving">
      <iframe
        width="660"
        height="371"
        src="https://www.youtube.com/embed/TircMu9yTa4"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <div class="video-driving">
      <h2>Watch a video in Chinese - 中文版本</h2>
      <iframe
        width="660"
        height="371"
        src="https://www.youtube.com/embed/05jMThMO-G0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.line {
    width: 90%;
    color: gainsboro;
    margin-top: 3rem;
}

.hot-title {
    text-align: center;
}
.tt-font {
    font-family: Georgia;
    font-size: 3.5rem;
}

.video-driving {
    text-align: center;
    margin: 3rem auto;
}

.hot-words {
    font-size: 1.5rem;
    font-family: Georgia;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 3rem;
}
</style>
