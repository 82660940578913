<template>
  <div class="middle">
    <div>
      <b-img src="@/assets/contact/about-us.jpg" class="rounded" fluid-grow alt="About us"></b-img>
    </div>
    <div>
      <div class="about mx-auto">
        <span class="soft-blue fredoka h2">{{ $t("KiaOraand") }}</span>
        <br />
        {{ $t("Travelingisnever") }}
        <span class="fredoka soft-blue">{{ $t("Top") }}</span>
        {{ $t("Priority") }}
        <br />
        {{ $t("Plus") }}
        <span class="fredoka soft-blue">{{ $t("Top") }}</span>
        {{ $t("Fantasticcarrental") }}
        <span class="fredoka soft-blue">{{ $t("Top") }}</span>
        {{ $t("Competitiveprice") }}
      </div>
      <div style="margin:3rem 1rem;">
        <b-row no-gutters align-h="center">
          <b-col cols="10" class="pr-md-4 col-md-5">
            <b-row no-gutters>
              <h3 class="company-title fredoka">{{ $t("OurCars") }}</h3>
              <div>
                <p>{{ $t("Allourrental") }}</p>
                <p>{{ $t("Alsoweprovide") }}</p>
              </div>
            </b-row>
            <b-row no-gutters class="mt-5 d-md-none d-lg-block">
              <h3 class="company-title fredoka">{{ $t("OurTeam") }}</h3>
              <div>
                <p>{{ $t("Ourprofessionaland") }}</p>
                <p>{{ $t("Letusto") }}</p>
              </div>
            </b-row>
          </b-col>

          <b-col cols="10" class="col-md-7">
            <b-img class="rounded hoverpointer mb-3" @click="showModal('company-one.jpg')" src="@/assets/contact/company-one.jpg" fluid-grow></b-img>
            <b-row no-gutters>
              <b-col cols="4" class="pr-2">
                <b-img class="rounded hoverpointer" @click="showModal('company-two.jpg')" src="@/assets/contact/company-two.jpg" fluid-grow></b-img>
              </b-col>
              <b-col cols="4" class="pr-1 pl-1">
                <b-img class="rounded hoverpointer" @click="showModal('company-three.jpg')" src="@/assets/contact/company-three.jpg" fluid-grow></b-img>
              </b-col>
              <b-col cols="4" class="pl-2">
                <b-img class="rounded hoverpointer" @click="showModal('company-four.jpg')" src="@/assets/contact/company-four.jpg" fluid-grow></b-img>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="d-none d-md-block d-lg-none">
          <h3 class="company-title fredoka">{{ $t("OurTeam") }}</h3>
          <div>
            <p>{{ $t("Ourprofessionaland") }}</p>
            <p>{{ $t("Letusto") }}</p>
          </div>
        </b-row>
        <b-modal ref="modal" hide-footer hide-header size="xl">
          <div v-if="!!imgPath">
            <b-img :src="require('@/assets/contact/'+imgPath)" fluid-grow></b-img>
          </div>
        </b-modal>
      </div>
      <div class="contactform" ref="contact-1">
        <b-img src="@/assets/contact/test.jpg" class="rounded" fluid-grow alt="About us"></b-img>
      </div>
      <b-card>
        <div>
          <b-card-text class="about mx-auto mt-0">{{ $t("Usetheonline") }}</b-card-text>
        </div>

        <div>
          <b-row align-h="around">
            <b-col md="5">
              <b-form class="contact-form" ref="form">
                <b-form-group id="input-group-1" :label="$t('FullName')+':'" label-for="input-1">
                  <b-form-input id="input-1" v-model="form.name" trim required :placeholder="$t('EnterFullName')"></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-2" :label="$t('EmailAddress')+':'" label-for="input-2">
                  <b-form-input id="input-2" trim v-model="form.email" type="email" required :placeholder="$t('Enter  Email')"></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" :label="$t('PhoneNumber')+':'" label-for="input-3">
                  <b-form-input id="input-3" type="tel" v-model="form.tel" trim :placeholder="$t('EnterPhoneNumber')"></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('Message')">
                  <b-form-textarea id="textarea" v-model="form.message" trim :placeholder="$t('EnterYourMessage')" rows="4" max-rows="6" required></b-form-textarea>
                </b-form-group>
                <b-row align-h="end" no-gutters>
                  <b-button variant="primary" @click="submitForm">{{ $t("Submit") }}</b-button>
                </b-row>
              </b-form>
            </b-col>

            <b-col md="4">
              <h3 class="fredoka mt-5 text-center text-md-left">{{ $t("Moredetails") }}</h3>

              <div class="mt-4">
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon open"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">{{ $t("OpenHours") }}</b-row>
                    <b-row>{{ $t("MonSun") }}</b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon phone"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">{{ $t("Phone") }}</b-row>
                    <b-row>
                      <a href="tel:0800 668 628">0800 668 628</a>
                      ({{ $t("NZ") }})
                    </b-row>
                    <b-row>
                      <a href="tel:1800 861 966">1800 861 966</a>
                      ({{ $t("AU") }})
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon email"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">{{ $t("Email") }}</b-row>
                    <b-row>
                      <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon wechat"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">{{ $t("WeChat") }}</b-row>
                    <b-row>toprentalnz</b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon whatsapp"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">WhatsApp</b-row>
                    <b-row>+64276682628</b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="1" class="h5 mr-1">
                    <i class="tt-icon address"></i>
                  </b-col>
                  <b-col>
                    <b-row class="h5">{{ $t("Address") }}</b-row>
                    <b-row>
                      <a href="https://maps.app.goo.gl/NorygiyKmtPjarJF8" target="_blank">40 Ascot Road, Mangere, Auckland 2022</a>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            imgPath: "",
            form: {
                email: "",
                name: "",
                tel: "",
                message: "",
            },
        };
    },
    methods: {
        showModal(imgPath) {
            this.imgPath = imgPath;
            this.$refs["modal"].show();
        },
        hideModal() {
            this.$refs["modal"].hide();
        },
        async submitForm() {
            if (this.$refs.form.reportValidity()) {
                try {
                    let language = localStorage.getItem("locale") || "en";
                    this.form.language = language;
                    let res = await this.$post("/api/contactUs", this.form);
                    this.form = {};
                    if(this.$i18n.locale === 'en'){
                      this.$bvToast.toast("Send Successfully!", { variant: "success", toaster: "b-toaster-top-center" });
                    }else{
                      this.$bvToast.toast("发送成功！", { variant: "success", toaster: "b-toaster-top-center" });
                    }
                } catch (err) {
                    const h = this.$createElement;
                    let list = (err.data || err.message).split("<br>").map(o => h("p", { class: ["text-center font-weight-bold"] }, o));
                    this.$bvModal.msgBoxOk(list, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
    },
    mounted() {
        let { pos } = this.$route.query;
        if (pos) {
            let contactform = this.$refs["contact-" + pos];
            contactform &&
                setTimeout(() => {
                    contactform.scrollIntoView();
                }, 100);
        }
    },
};
</script>

<style scoped>
.about {
    font-size: 1.2rem;
    width: 88%;
    text-align: center;
    margin-top: 2rem;
}
.company-title {
    font-weight: bolder;
    font-weight: 100;
}
p {
    font-size: 1.2rem;
}

.contact-form {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
    margin: 3rem auto 1rem;
}

div > ul {
    list-style-type: none;
}
</style>