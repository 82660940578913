<template>
  <div class="middle">
    <div>
      <div class="d-flex justify-content-lg-around flex-lg-row flex-column">
        <b-card title="Pick up" no-body class="col-lg-6 border-0">
          <h5 class="text-dark font-weight-bold">{{ $t("Pickup") }}</h5>
          <div class="d-flex flex-column flex-lg-row">
            <b-form-select :disabled="locationOptions.length == 1" size="sm" class="col-lg-4" :options="locationOptions" v-model="order.pickUpLocation"></b-form-select>
            <b-form-datepicker
              reset-button
              close-button
              size="sm"
              class="col-lg-4"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="order.pDate"
              :min="tomorrowDay"
              locale="en-GB"
              placeholder="Select date"
            ></b-form-datepicker>
            <b-form-timepicker
              class="col-lg-4"
              hide-header
              minutes-step="30"
              :hour12="false"
              locale="en-GB"
              reset-button
              size="sm"
              placeholder="Select time"
              :show-seconds="false"
              @context="onContext($event, 'pTime')"
              reset-value="12:00"
              v-model="order.pTime"
            ></b-form-timepicker>
          </div>
        </b-card>

        <b-card no-body title="Return" class="col-lg-6 border-0">
          <h5 class="text-dark font-weight-bold">{{ $t("Return") }}</h5>
          <div class="d-flex flex-column flex-lg-row">
            <b-form-select :disabled="locationOptions.length == 1" size="sm" class="col-lg-3" :options="locationOptions" v-model="order.dropOffLocation"></b-form-select>
            <b-form-datepicker
              reset-button
              close-button
              size="sm"
              class="col-lg-4"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="order.rDate"
              :min="order.pDate"
              locale="en-GB"
              placeholder="Select date"
            ></b-form-datepicker>
            <b-form-timepicker
              class="col-lg-3"
              hide-header
              minutes-step="30"
              :hour12="false"
              locale="en-GB"
              reset-button
              size="sm"
              placeholder="Select time"
              :show-seconds="false"
              @context="onContext($event, 'rTime')"
              reset-value="12:00"
              v-model="order.rTime"
            ></b-form-timepicker>
            <b-button @click="findCar" size="sm" style="padding: 0.25rem;" squared variant="dark" type="submit" class="col-lg-2 mt-2 mt-lg-0">find{{ $t("") }}</b-button>
          </div>
        </b-card>
      </div>
    </div>
    <b-overlay :show="showOverlay" rounded="sm">
      <div class="d-flex flex-row flex-wrap">
        <div class="col-lg-6 px-0 px-sm-3" v-for="car in cars" :key="car.id">
          <b-card no-body class="my-2">
            <b-card-title class="text-dark mb-0">
              <b-row class="pl-4 car-title p-0 h1">
                {{ $t(removeBlank(car.model)) }}
                <b-img src="@/assets/new.jpg" fluid class="position-absolute new-img" v-if="car.newModel"></b-img>
              </b-row>
              <b-row class="pl-4 font-weight-bold">{{ car.modelYear }} {{ $t("orsimilar") }}</b-row>
              <!-- <i class="h1 float-right tt-icon new text-warning mt-n1" v-if="car.price % 2"></i> -->
            </b-card-title>
            <b-row class="bg-light-secondary text-white mt-2" align-h="around" no-gutters>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class="bg-light-secondary">
                <i class="tt-icon passenger">{{ car.passengers }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon cardoor">{{ car.doors }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon luggage">{{ car.luggage }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class="ml-1 ml-lg-0">
                <i class="tt-icon engine">{{ car.engine }}</i>
              </b-col>
              <b-col cols="auto" variant="secondary" size="sm" type="link" class>
                <i class="tt-icon transmission">{{ $t(removeBlank(car.transmission)) }}</i>
              </b-col>
            </b-row>
            <b-row no-gutters class="grey-gradient car-height pb-2">
              <b-card-img :src="car.photo" alt="Image" left class="col-lg-8" style="[;height:100%;]"></b-card-img>
              <div class="col-lg-4 text-lg-left text-center pt-2 pt-lg-4">
                <p class="h1">
                  ${{ car.carAvgPrice }}
                  <span class="h3">{{ $t("Day") }}</span>
                </p>
                <p class="h5">{{ $t("For") }} {{ car.dateSum }} {{ $t("Days") }} ${{ car.carTotalMoney }}</p>
                <div class="pt-1 pt-lg-4"></div>
                <b-button v-if="selloutCars.indexOf(car.id)===-1" variant="info" size="lg" class="shadow col-10" @click="selectCar(car)">{{ $t("Select") }}</b-button>
                <b-button v-else variant="secondary" size="lg" class="shadow col-10">{{ $t("SoldOut") }}</b-button>
              </div>
            </b-row>
            <b-row class="d-flex mt-sm-1 py-2 py-lg-3 border-top no-gutters">
              <span class="col-md-3 col-sm-5 col-lg-5 offset-1" v-for="(conf, i) in (car.configure || '').split(',')" :key="i">
                <i class="tt-icon duigou text-warning"></i>
                {{ $t(removeBlank(conf)) }}
              </span>
            </b-row>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import utility from "../utils/utility";
export default {
    data() {
        let tomorrowDay = this.$dayjs(new Date()).add(24, "hour").format("YYYY-MM-DD");
        return {
            tomorrowDay: tomorrowDay,
            locationOptions: [{ value: "Auckland Airport", text: "Auckland Airport" }],
            bookContent: { carId: "" },
            cars: [],
            carId: "",
            order: {
                pickUpLocation: "Auckland Airport",
                dropOffLocation: "Auckland Airport",
                pDate: this.$dayjs(new Date()).add(2, "day").format("YYYY-MM-DD"),
                rDate: this.$dayjs(new Date()).add(5, "day").format("YYYY-MM-DD"),
                pTime: "12:00",
                rTime: "12:00",
            },
            showOverlay: false,
            selloutCars: [],
        };
    },
    methods: {
        ...utility,
        onContext({ hours, minutes }, field) {
            if (hours === null) {
                this.order[field] = "12:00";
            }
            if (hours < 9) {
                this.order[field] = "09:00";
            } else if (hours > 18 || (hours === 18 && minutes !== 0)) {
                this.order[field] = "18:00";
            }
        },
        async findCar() {
            let error = this.dateRangeError(this.order);
            if (error) {
                this.$bvModal.msgBoxOk(error, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return;
            }
            let dateObj = this.dateRangeForMoney(this.order);
            this.showOverlay = true;
            try {
                let res = await this.$post("/api/getBookingCars", dateObj);
                this.cars = res.data;
                this.verifySoldout();
            } finally {
                this.showOverlay = false;
            }
        },

        async verifySoldout() {
            if (!(await this.verifyInShutdown())) {
                this.findSellout();
            }
        },

        async findSellout() {
            let dateObj = this.deconstructDate(this.order);
            let res = await this.$post("/api/getSelloutCars", dateObj);
            this.selloutCars = res.data;
        },

        async verifyInShutdown() {
            let dateObj = this.deconstructDate(this.order);
            let res = await this.$post("/api/verifyDateIsIn", dateObj);
            if (res.data) {
                this.selloutCars = this.cars.map(o => o.id);
                return true;
            } else {
                this.selloutCars = [];
            }
            return false;
        },

        selectCar(car) {
            let error = this.dateRangeError(this.order);
            if (error) {
                this.$bvModal.msgBoxOk(error, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return;
            }
            sessionStorage.setItem("order", JSON.stringify(Object.assign(this.order, { carId: car.id })));
            this.$router.push("options");
        },
    },
    computed: {
        discountMoney() {
            if (this.bookContent.days <= 5) {
                return this.bookContent.totalPrice;
            }
            if (this.bookContent.discount === "Longtrip05") {
                return this.bookContent.totalPrice > 31 ? this.bookContent.totalPrice - 30 : 1;
            } else if (this.bookContent.discount === "Earlybird20") {
                return this.bookContent.totalPrice * 0.8;
            } else {
                return this.bookContent.totalPrice;
            }
        },
        pickUpTime() {
            return this.order.pDate + " " + this.order.pTime;
        },
        dropOffTime() {
            return this.order.rDate + " " + this.order.rTime;
        },
    },
    mounted() {
        let order = sessionStorage.getItem("order");
        if (!order) {
            this.order = {
                pickUpLocation: "Auckland Airport",
                dropOffLocation: "Auckland Airport",
            };
        } else {
            this.order = JSON.parse(order);
        }
        this.findCar();
    },
};
</script>

<style scoped>
.grey-gradient {
    background: linear-gradient(110deg, #e0e0e0 0, rgba(224, 224, 224, 0) 50%);
}

.new-img {
    width: 150px;
    right: 25px;
    top: 20px;
}
@media screen and (min-width: 1280px) {
    .car-height {
        height: 15rem;
    }
}
@media screen and (min-width: 992px) and (max-width: 1118px) {
    .new-img {
        width: 100px;
        right: 20px;
        top: 42px;
    }
}
@media screen and (max-width: 528px) {
    .new-img {
        width: 100px;
        right: 5px;
        top: 32px;
    }
}

.bg-light-secondary {
    background: #626365 linear-gradient(180deg, #8d8f92, #818182) repeat-x;
    border-color: #dee2e6;
}

.bg-footer {
    background-color: #dedfe0de;
}
</style>
