<template>
  <div class="middle">
    <div class="mt-5 mx-2 mx-md-5" v-if="$i18n.locale === 'en'">
      <h4 class="fredoka">All the information you need to know about the bond and credit card:</h4>
      <ol style="font-size:1.2rem">
        <li>A pre-authorisation deposit (as security bond) will be taken and hold from driver’s credit card. (Note: Credit card must have driver’s name on)</li>
        <li>Credit Card or Debit Card must have Visa or Mastercard logo. We do NOT accept American Express, Q Card, Dinner Club card as for security bond.</li>
        <li>
          The amount of the pre-authorisation deposit is up to $500 NZD depending on the selected insurance option.
          <span
            @click="goto('/insurance')"
            class="hover-universal"
          >(Click here for more details)</span>
        </li>
        <li>The pre-authorisation deposit will release back to customers’ card issued bank within 10-15 working days after return the vehicle.</li>
      </ol>
      <h4 class="fredoka mt-4">What is the pre-authorisation?</h4>
      <p style="font-size:1.2rem;line-height: 2.2rem;">
        The pre-authorisation (as security deposit) is simply a temporary hold of the amount on your credit card.
        <b>Please note - it simply is "frozen”, and it is NOT an actual charged amount. Most credit card accounts shown as a pending transaction.</b>
      </p>
      <h4 class="fredoka mt-4">When do I receive my deposit back?</h4>
      <p
        style="font-size:1.2rem;line-height: 2.2rem;"
      >When you return your vehicle, the pre-authorisation will be released/cancelled, and bank will take extra working days to process (Normally 5-7 working days, and up to 28 days depends on your bank card issuer).</p>
      <p style="font-size:1.2rem;line-height: 2.2rem;">
        <b>Please keep in mind that</b> you won't see "refund" money back into your credit card account. Once this “on hold” of pre-authorisation be released, then this pending transaction will “disappear”.
      </p>
      <p
        style="font-size:1.2rem;line-height: 2.2rem;"
      >If after the 10-business day period is finished and you are still able to see the holding deposit, please contact our customer service team.</p>
    </div>
    <div class="mt-5 mx-2 mx-md-5" v-else>
      <h4 class="fredoka">以下信息是关于押金和信用卡的解释:</h4>
      <ol style="font-size:1.2rem">
        <li>押金将以预授权的方式暂时冻结，只接受司机本人的信用卡。（说明：信用卡需要有司机本人姓名）</li>
        <li>信用卡或借记卡必须有Visa或万事达（Mastercard）标志。我们不接受美国运通卡（American Express）、Q Card、Dinner Club卡作为担保。</li>
        <li>
          根据所选择的保险选项，预授权押金的金额最高为500新西兰元。
          <span @click="goto('/insurance')" class="hover-universal">(请按此浏览详情)</span>
        </li>
        <li>预授权保证金将于车辆归还后10-15个工作日内退回客户发卡银行</li>
      </ol>
      <h4 class="fredoka mt-4">什么是预先授权?</h4>
      <p style="font-size:1.2rem;line-height: 2.2rem;">
        预授权(作为保证金)只是暂时持有您信用卡上的金额。
        <b>请注意-它只是“冻结”，而不是实际收取的金额。大多数信用卡账户显示为待处理交易。</b>
      </p>
      <h4 class="fredoka mt-4">我什么时候能收到押金?</h4>
      <p style="font-size:1.2rem;line-height: 2.2rem;">当您归还车辆时，预授权将被释放/取消，银行将需要额外的工作日来处理(通常为5-7个工作日，最多28天取决于您的银行卡发卡机构)。</p>
      <p style="font-size:1.2rem;line-height: 2.2rem;">
        <b>请注意</b>您不会看到“退款”的钱回到你的信用卡帐户。一旦这个预授权的“待处理”被取消，那么这个待处理的交易记录将“消失”。
      </p>
      <p style="font-size:1.2rem;line-height: 2.2rem;">如果在10个工作日结束后，您仍然可以看到此条预授权记录，请联系我们的客户服务团队。</p>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        goto(route) {
            this.$router.push(route);
        },
    },
};
</script>

<style scoped>
ol > li {
    margin-top: 0.5rem;
}
</style>