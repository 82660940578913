<template>
  <div class="middle">
    <b-row align-h="between">
      <b-col>
        <b-form-group id="input-group-1" label="Booking ID:" label-for="input-1">
          <b-form-input id="input-1" trim v-model="param.bkNbr" required></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-2" label="Last Name:" label-for="input-2">
          <b-form-input id="input-2" trim v-model="param.lastName" required></b-form-input>
        </b-form-group>
      </b-col>
      <div class="w-100 d-sm-none"></div>
      <b-col>
        <b-form-group id="input-group-4" label="Model:" label-for="input-5">
          <div style="position:relative;">
            <b-form-select id="input-5" v-model="param.carId" required :options="carOptions"></b-form-select>
            <i class="tt-icon chahao hoverpointer" @click="param.carId =''" style="position:absolute;right:1.5rem;top:0.5rem;"></i>
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-4" label="Status:" label-for="input-5">
          <b-form-select id="input-5" v-model="param.status" required :options="statusOptions"></b-form-select>
        </b-form-group>
      </b-col>
      <div class="w-100"></div>
      <b-col>
        <b-form-group id="input-group-5" label="Tel:" label-for="input-5">
          <b-form-input id="input-5" trim v-model="param.tel" required></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-3" label="Pickup Date:" label-for="input-4">
          <div style="position:relative;">
            <flat-pickr v-model="param.pickUpDateRange" :config="dateConfig" class="form-control" name="date"></flat-pickr>
            <i class="tt-icon chahao hoverpointer" @click="param.pickUpDateRange =''" style="position:absolute;right:1rem;top:0.5rem;"></i>
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-3" label="Return Date:" label-for="input-4">
          <div style="position:relative;">
            <flat-pickr v-model="param.dropOffDateRange" :config="dateConfig" class="form-control" name="date"></flat-pickr>
            <i class="tt-icon chahao hoverpointer" @click="param.dropOffDateRange =''" style="position:absolute;right:1rem;top:0.5rem;"></i>
          </div>
        </b-form-group>
      </b-col>
      <b-col class="ver">
        <b-button variant="info" style="position: absolute;bottom:1rem;" @click="refresh">Search</b-button>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded>
      <b-table
        striped
        head-variant="dark"
        sticky-header="33rem"
        hover
        small
        caption-top
        responsive="lg"
        bordered
        :fields="fields"
        :items="provide"
        :per-page="perPage"
        :current-page="currentPage"
        no-provider-sorting
        style="min-height:39rem;"
        ref="table"
      >
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(bk_nbr)="data">
          <b-button  variant="link" @click="goToManage(data.item)">{{ data.item.bk_nbr }}</b-button>
        </template>
        <template #cell(created_date)="data">
          <span class="text-truncate d-inline-block">{{data.item.created_date}}</span>
        </template>
        <template #cell(pick_up_time)="data">
          <span class="text-truncate d-inline-block">{{data.item.pick_up_time}}</span>
        </template>
        <template #cell(drop_off_time)="data">
          <span class="text-truncate d-inline-block">{{data.item.drop_off_time}}</span>
        </template>
        <template #cell(model)="data">
          <span style="max-width:12rem;" class="text-truncate d-inline-block">{{(data.item.model_year).split("-")[0] + " " + data.item.model}}</span>
        </template>
        <template #cell(tel)="data">
          <span class="text-truncate d-inline-block">{{(data.item.tel).replaceAll(" ","")}}</span>
        </template>
        <template v-slot:cell(name)="data">
          <span style="max-width:6rem;" class="text-truncate d-inline-block">{{ data.item.first_name + " "+data.item.last_name }}</span>
        </template>
        <template v-slot:cell(total_pay)="data">
          <span class="h6 hoverpointer d-inline-block" @click="data.item.status=='10' ? discountModalOpen(data.item) : ''">
            {{ `${data.item.total_pay}` }}
            <i v-if="data.item.status=='10'" class="tt-icon edit ml-1" style="color:#af8508;"></i>
          </span>
        </template>
        <template v-slot:cell(status)="data">
          <b-badge :variant="data.item.status==='10'?'primary':data.item.status==='20'?'success':'danger'">{{ data.item.status | orderStatus }}</b-badge>
        </template>
        <template v-slot:cell(operation)="data">
          <b-row align-h="between" class="px-3" style="width:6rem;" no-gutters>
            <i v-if="data.item.status === '10'" @click="confirm(data.item.id,data.item.bk_nbr)" class="h4 highlight-duigou hoverpointer tt-icon duigou1 text-success"></i>
            <i v-else class="h4 tt-icon duigou1 text-grey"></i>
            <i v-if="data.item.status != '30'" @click="cancel(data.item.id,data.item.bk_nbr)" class="h4 hoverpointer highlight-chahao tt-icon chahao text-danger"></i>
            <i v-else class="h4 tt-icon chahao text-grey"></i>
          </b-row>
        </template>
        <b-tr slot="custom-foot" class="text-right">
          <b-td colspan="11" class="text-right">
            <b-pagination :total-rows="rows" align="right" v-model="currentPage" :per-page="perPage" aria-controls="my-table"></b-pagination>
          </b-td>
        </b-tr>
      </b-table>
    </b-overlay>
    <b-row align-h="end" no-gutters>
      <b-button size="lg" variant="info" @click="$router.go(-1)">Back</b-button>
    </b-row>
    <b-modal id="discountModal" title="Discount Info" size="lg" no-close-on-backdrop @ok="discountEditOk">
      <b-form>
        <b-form-group id="tag" label="Booking Number" label-for="Booking Number">
          <b-form-input id="tag-input" readonly v-model="order.bkNbr"></b-form-input>
        </b-form-group>
        <b-form-group id="customerName" label="Customer Name" label-for="Customer Name">
          <b-form-input id="customerName-input" readonly v-model="order.name"></b-form-input>
        </b-form-group>

        <b-form-group id="totalPrice" label="Total Price" label-for="Total Price">
          <b-form-input id="totalPrice-input" readonly v-model.number.trim="order.totalPrice"></b-form-input>
        </b-form-group>

        <b-form-group id="discount" label="Discount" label-for="Discount">
          <template slot="label">
            Discount
            <i class="tt-icon edit text-info" />
          </template>
          <b-form-input id="discount-input" v-model.number.trim="order.discount"></b-form-input>
          <div class="font-weight-lighter m-l-2 text-secondary" v-if="order.oldDiscount != order.discount">old: {{order.oldDiscount}}</div>
        </b-form-group>

        <b-form-group id="totalPay" label="Total Pay" label-for="Total Pay">
          <template slot="label">
            Total Pay
            <i class="tt-icon edit text-info" />
          </template>
          <b-form-input id="totalPay-input" v-model.number.trim="order.totalPay"></b-form-input>
          <div class="font-weight-lighter m-l-2 text-secondary" v-if="order.oldTotalPay != order.totalPay">old: {{order.oldTotalPay}}</div>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <b-button variant="secondary" @click="$bvModal.hide('discountModal')">Cancel</b-button>
        <b-button variant="info" @click="discountEditOk()">Save Edit</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
    components: {
        flatPickr,
    },
    data() {
        return {
            dateConfig: {
                altInput: true,
                altFormat: "Y-m-d",
                mode: "range",
            },
            param: {
                carId: "",
                status: "",
                pickUpDateRange: "",
            },
            carOptions: [],
            showLoading: false,
            perPage: 10,
            currentPage: 1,
            rows: 0,
            fields: [
                { label: "", key: "index" },
                { label: "Booking No.", key: "bk_nbr", sortable: true },
                // "bkNbr",
                "name",
                "model",
                "tel",
                { label: "Pickup", key: "pick_up_time", sortable: true },
                { label: "Return", key: "drop_off_time", sortable: true },
                { label: "Total Price", key: "total_pay", sortable: true },
                { label: "Days", key: "total_day", sortable: true },
                "status",
                { label: "Booking Time", key: "created_date", sortable: true },
                "operation",
            ],
            statusOptions: [
                { text: "All", value: "" },
                { text: "Pending", value: "10" },
                { text: "Confirmed", value: "20" },
                { text: "Canceled", value: "30" },
            ],
            order: {
                orderNbr: "",
                totalPrice: "",
                discount: "",
                totalPay: "",
                status: "",
            },
        };
    },
    methods: {
        async getCarOptions() {
            let { data } = await this.$post("/api/getCars", { size: "all" });
            let list = data.map(o => {
                return { text: o.model, value: o.id };
            });
            list.splice(0, 0, { text: "All", value: "" });
            this.carOptions = list;
        },
        async getOrder(currentPage, perPage) {
            let res = await this.$post("/getAllBooking", { param: JSON.stringify(this.param), currentPage: currentPage, perPage: perPage });
            return res.data;
        },
        async provide({ currentPage, perPage }) {
            this.showLoading = true;
            try {
                let { rows, list } = await this.getOrder(currentPage, perPage);
                this.rows = rows;
                return list;
            } finally {
                this.showLoading = false;
            }
        },
        refresh() {
            this.$refs.table.refresh();
        },
        async confirm(id, bkNbr) {
            let res = await this.$bvModal.msgBoxConfirm(`Are you sure confirm order [ ${bkNbr} ]?`, {
                buttonSize: "sm",
                okVariant: "success",
                okTitle: "YES",
                centered: true,
                cancelTitle: "NO",
            });
            if (res) {
                try {
                    res = await this.$post("/confirmOrder", { id: id });
                    this.refresh();
                } catch (err) {
                    this.$bvModal.msgBoxOk(err.message, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
        async cancel(id, bkNbr) {
            let res = await this.$bvModal.msgBoxConfirm(`Are you sure cancel order [ ${bkNbr} ]?`, {
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                centered: true,
                cancelTitle: "NO",
            });
            if (res) {
                try {
                    res = await this.$post("/cancelOrder", { id: id });
                    this.refresh();
                } catch (err) {
                    this.$bvModal.msgBoxOk(err.message, {
                        size: "sm",
                        okVariant: "danger",
                        okTitle: "OK",
                        footerClass: "p-1",
                        hideHeaderClose: true,
                        centered: true,
                    });
                }
            }
        },
        goToManage({ bk_nbr, last_name }) {
            let route = this.$router.resolve({ path: "/manage", query: { bkNbr: bk_nbr, lastName: last_name } });
      window.open(route.href, '_blank');

            // this.$router.push({ path: "/manage", query: { bkNbr: bk_nbr, lastName: last_name } });
        },
        async discountModalOpen({ id: bkId }) {
            let {
                data: { id, firstName, lastName, bkNbr, totalPrice, totalPay, discount },
            } = await this.$post("/api/getOrderById", { id: bkId });
            this.order = {
                id: id,
                name: firstName + " " + lastName,
                bkNbr: bkNbr,
                totalPrice: totalPrice,
                totalPay: totalPay,
                discount: discount,
                oldTotalPrice: totalPrice,
                oldTotalPay: totalPay,
                oldDiscount: discount,
            };
            this.$root.$emit("bv::show::modal", "discountModal");
        },
        parseNumber(num) {
            return parseFloat(parseFloat(num).toFixed(2).toString());
        },
        async discountEditOk() {
            try {
                await this.$post("/api/saveDiscount", this.order);
                this.$root.$emit("bv::hide::modal", "discountModal");
                this.refresh();
            } catch (err) {
                this.$bvModal.msgBoxOk(err.data || err.message || err, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
    },
    mounted() {
        this.getCarOptions();
    },
    watch: {
        "order.discount": {
            deep: true,
            handler(val) {
                this.order.totalPay = this.parseNumber(this.order.totalPrice - val);
            },
        },
        "order.totalPay": {
            deep: true,
            handler(val) {
                this.order.discount = this.parseNumber(this.order.totalPrice - val);
            },
        },
    },
};
</script>

<style scoped>
ol,
ul,
dl {
    margin-top: 1rem;
    margin-bottom: 0 !important;
}

.highlight-duigou:hover {
    background-color: #a8e6cf;
    border-radius: 50%;
}
.highlight-chahao:hover {
    background-color: #ffe2e2;
    border-radius: 100%;
}
.text-grey {
    color: #c9d6df;
}
</style>