<template>
  <div>
    <b-row class="mt-5" align-h="center" align-v="center" style="min-height: 50vh;">
      <b-card no-body class="border-1 py-3 px-5 col-9 col-md-6 col-lg-5">
        <h2 class="text-center">Login</h2>
        <b-form-group>
          <template slot="label">
            <span class="font-weight-bold">username</span>
            <span class="text-danger">&nbsp;*</span>
          </template>
          <b-input v-model="username" trim size="lg" class="my-3" placeholder="username"></b-input>
        </b-form-group>
        <b-form-group>
          <template slot="label">
            <span class="font-weight-bold">password</span>
            <span class="text-danger">&nbsp;*</span>
          </template>
          <b-input v-model="password" type="password" trim size="lg" class="my-3" placeholder="password" @keyup.enter="submit"></b-input>
        </b-form-group>
        <b-button size="lg" class="my-3" variant="info" @click="submit">login</b-button>
      </b-card>
    </b-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            username: "",
            password: "",
            fa: "",
        };
    },
    methods: {
        async submit() {
            try {
                let res = await this.$post("/auth/login", {
                    username: this.username,
                    password: this.password,
                });
                localStorage.setItem("token", res.data);
                let now = new Date().getTime();
                let expireDate = now + 1000 * 60 * 60 * 24 * 3;
                localStorage.setItem("token-expire-date", expireDate);
                sessionStorage.setItem("token",res.data);

                if (!!this.fa) {
                    this.$router.push(this.fa);
                } else {
                    this.$router.push("/admin");
                }
            } catch (err) {
                this.$bvModal.msgBoxOk("Login Failed", {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
            }
        },
    },
    mounted() {
        let { fa } = this.$route.query;
        if (!!fa) {
            this.fa = fa;
        }
    },
};
</script>

<style></style>
