<template>
  <div class="middle-c" id="print-zone">
    <div class="text-right">
      <b-img fluid src="../assets/logo-bottom.jpg" style="width:20%" class="mr-0"></b-img>
    </div>
    <b-row class="mt-2">
      <b-col cols="6">
        Top Rental
        <br />
        <a href="https://maps.app.goo.gl/NorygiyKmtPjarJF8" target="_blank">40 Ascot Road, Mangere, Auckland 2022</a>
        <br />New Zealand
      </b-col>
      <b-col cols="6" class="text-right">
        <a href="tel:0800 668 628">0800 668 628</a>
        <br />
        <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a>
        <br />www.toprental.co.nz
      </b-col>
    </b-row>
    <p class="h5 text-center">VEHICLE RENTAL AGREEMENT</p>Booking Number:
    <input class="border-0" />
    <br />CUSTOMER/ PRIMARY DRIVER
    <span class="chinese">主驾驶员</span>
    <table class="table table-bordered table-driver">
      <tr>
        <th>
          <label>
            First Name
            <br />
            <span class="chinese">名</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Last Name
            <br />
            <span class="chinese">姓</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Date Of Birth
            <br />
            <span class="chinese">出生日期</span>
          </label>
          <input />
        </th>
      </tr>
      <tr>
        <th>
          <label>
            Phone
            <br />
            <span class="chinese">电话</span>
          </label>
          <input />
        </th>

        <th colspan="2">
          <label>
            Email
            <br />
            <span class="chinese">电子邮件</span>
          </label>
          <input />
        </th>
      </tr>

      <tr>
        <th colspan="2">
          <label>
            Driver License Number
            <br />
            <span class="chinese">驾照号码</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Expired Date
            <br />
            <span class="chinese">过期日期</span>
          </label>
          <input />
        </th>
      </tr>
    </table>ADDITIONAL DRIVER (if applicable)
    <span class="chinese">额外副驾驶员（如适用）</span>
    <table class="table table-bordered table-driver">
      <tr>
        <th>
          <label>
            First Name
            <br />
            <span class="chinese">名</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Last Name
            <br />
            <span class="chinese">姓</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Date Of Birth
            <br />
            <span class="chinese">出生日期</span>
          </label>
          <input />
        </th>
      </tr>
      <tr>
        <th>
          <label>
            Phone
            <br />
            <span class="chinese">电话</span>
          </label>
          <input />
        </th>

        <th colspan="2">
          <label>
            Email
            <br />
            <span class="chinese">电子邮件</span>
          </label>
          <input />
        </th>
      </tr>

      <tr>
        <th colspan="2">
          <label>
            Driver License Number
            <br />
            <span class="chinese">驾照号码</span>
          </label>
          <input />
        </th>

        <th>
          <label>
            Expired Date
            <br />
            <span class="chinese">过期日期</span>
          </label>
          <input />
        </th>
      </tr>
    </table>
    <b-row>
      <b-col>
        THE RENTAL VEHICLE(S)
        <span class="chinese">租赁车辆信息</span>
        <table class="table table-bordered table-carinfo">
          <tr>
            <th>
              Type of Vehicle
              <br />
              <span class="chinese">车辆组别</span>
            </th>
            <td>
              <input list="car" />
              <datalist id="car">
                <option>Small Hatchback</option>
                <option>Mid SUV</option>
                <option>Large SUV</option>
                <option>MPV</option>
              </datalist>
            </td>
          </tr>
          <tr>
            <th>
              Make
              <br />
              <span class="chinese">品牌</span>
            </th>
            <td>
              <input list="make" />
              <datalist id="make">
                <option>Toyota</option>
                <option>Nissan</option>
              </datalist>
            </td>
          </tr>
          <tr>
            <th>
              Model
              <br />
              <span class="chinese">车型</span>
            </th>
            <td>
              <input list="model" />
              <datalist id="model">
                <option v-for="model in models" :key="model">{{ model }}</option>
              </datalist>
            </td>
          </tr>
          <tr>
            <th>
              Color
              <br />
              <span class="chinese">颜色</span>
            </th>
            <td>
              <input list="color" />
              <datalist id="color">
                <option>White</option>
                <option>Silver</option>
              </datalist>
            </td>
          </tr>
          <tr>
            <th>
              Registration No.
              <br />
              <span class="chinese">车牌号码</span>
            </th>
            <td>
              <input />
            </td>
          </tr>
        </table>
      </b-col>
      <b-col>
        RENTAL PERIOD
        <span class="chinese">租赁时长信息</span>
        <div class="table-bordered table-period">
          <p>
            Renter agrees to rent the described vehicle for the following period:
            <span class="chinese">租赁者同意租期如下</span>
          </p>
          <b-row class="ml-0" no-gutters>
            <b-col cols="8">
              <label>
                Start Date:
                <span class="chinese">起始日期</span>
              </label>
              <input id="startDate" type="date" class="border-0" />
            </b-col>

            <b-col>
              <label>
                Time:
                <span class="chinese">时间</span>
              </label>
              <input type="time" class="border-0" />
            </b-col>
          </b-row>

          <b-row class="ml-0" no-gutters>
            <b-col cols="8">
              <label>
                Due Date:
                <span class="chinese">结束日期</span>
              </label>
              <input id="dueDate" type="date" class="border-0" />
            </b-col>

            <b-col>
              <label>
                Time:
                <span class="chinese">时间</span>
              </label>
              <input type="time" class="border-0" />
            </b-col>
          </b-row>

          <b-row class="ml-0">
            Duration:
            <span class="chinese">租期时长</span>
            <input />Days（天）
          </b-row>
        </div>
      </b-col>
    </b-row>
    <div class="table-bordered">
      <h5>
        <u class="mt-3">Standard Liability Declaration</u>
        <span class="chinese">标准车损责任声明</span>
      </h5>
      <p>
        An standard liability excess fee of $4,025 NZD (incl GST) applies in the event of an accident or damage to each vehicle(s). This is the
        amount you are liable for and it is referred to as your liability. You will be asked to charge from your credit card. If the cost of the
        damage is lower than the excess, the difference will be refunded to you once the claim has been processed.
        <br />
        <span class="chinese">
          所有租赁车辆免费提供基本车损责任保险。如果发生事故或损坏，每辆车则需支付 $4025 NZD 的垫底费 (含GST). 这是您应承担的责任和金额。这笔金
          额将从您的信用卡中扣除。如果损坏的费用低于该金额，则一旦处理完毕，差额将退还给您
        </span>
      </p>
    </div>
    <div class="table-bordered mt-3">
      <h5>
        Extra Insurance Options
        <span class="chinese">附加保险选项</span>
      </h5>
      <b-row no-gutters>
        <b-col cols="7">
          <u>
            <b>Protection Insurance</b>
          </u>
        </b-col>
        <b-col cols="4">
          <label class="mr-5">($21 NZD per day):</label>Yes
          <input type="checkbox" class="tick-mark" />
        </b-col>
        <b-col cols="1">
          No
          <input type="checkbox" class="tick-mark" />
        </b-col>
      </b-row>
      <p>
        Rental vehicle excess fee reduce to $300 NZD.
        <br />Your vehicle theft cover included.
        <br />Window screen cover included.
        <br />AA roadside assistance call-out fee included.
        <br />
        <span class="chinese">自身车辆垫底费降到$300 NZD, 并包含盗抢险，挡风玻璃，道路救援CALL OUT费用.</span>
      </p>
      <b-row no-gutters>
        <b-col cols="7">
          <u>
            <b>Premium Insurance</b>
          </u>
        </b-col>
        <b-col cols="4">
          <label class="mr-5">($29 NZD per day):</label>Yes
          <input type="checkbox" class="tick-mark" />
        </b-col>
        <b-col cols="1">
          No
          <input type="checkbox" class="tick-mark" />
        </b-col>
      </b-row>
      <p>
        Included all protection insurance cover above, Plus below:
        <br />Rental vehicle excess fee reduce to $0 NZD.
        <br />Third party vehicle / property damage Cover
        <br />
        <span class="chinese">包含Protection保险套餐全部并额外包含自身车辆垫底费为0，第三者责任险垫底费为0.</span>
      </p>
    </div>
    <hr class="bottom-line" />
    <div class="text-center">Page 1 of 3</div>
    <div style="page-break-after: always;"></div>
    <!-- Second page -->
    <div class="text-right mb-1">
      <b-img fluid src="../assets/logo-bottom.jpg" style="width:20%" class="mr-0"></b-img>
    </div>
    <div class="table-bordered">
      <h5>
        <u>RULES OF ACTION (see next page for FULL Terms and Conditions)</u>
        <span class="chinese">重要条款 （合同条款细节请查阅下页）</span>
      </h5>
      <p>
        It is absolutely PROHIBITED to use this rented vehicle by anyone not listed on the rental agreement. Each driver must be pre- qualified in
        person with valid drivers license and be a minimum of 21 years of age. If Driver is under the age of 25 additional fees may apply.
        <br />
        <span class="chinese">禁止合同以外的人开车。最低年龄要求21周岁以上，如年龄在21-25周岁，将会产生年轻驾驶员费，为每人每天$10 NZD</span>
      </p>
      <p>
        It is absolutely PROHIBITED to use or operate this rented vehicle by anyone who is under the influence of ALCOHOL or any NARCOTICS.
        <br />
        <span class="chinese">禁止酒驾和毒驾</span>
      </p>
      <p>
        There is absolute NO SMOKING allowed in the rented vehicle under any circumstances. Any found evidence of any kind of smoking in the
        vehicle a fine of $400.00 regardless of damage or not will apply.
        <br />
        <span class="chinese">禁止车内吸烟，如有发现将处以$400 NZD罚款</span>
      </p>
      <p>
        No cell phone use while operating the Vehicle unless it is a hands free device such as a Bluetooth. It’s the law, the driver is prohibited from
        handling any Electronic device while driving regardless of the behavior of use.
        <br />
        <span class="chinese">禁止在行驶过程中使用手机，请务必遵守新西兰法律，车载蓝牙是被允许使用</span>
      </p>
      <p>
        ALL TICKETS, FINES, FEES and other (see T&C) received during the rental period, caused as a result of the renter and/ or rental period must
        be paid by the Renter.
        <br />
        <span class="chinese">租期内产生的超速，违章停车，过路费等罚单，为租赁者/司机承担</span>
      </p>
      <p>
        In case of LOST, DAMAGED or LOCKED INSIDE KEYS requiring key recovery a maximum charge of $600.00 may apply.
        <br />
        <span class="chinese">如有钥匙丢失，损坏以及反锁车门等情况，钥匙维修更换费用为$600 NZD, 请妥善保管钥匙</span>
      </p>
      <p>
        The passenger capacity of this vehicle is determined by the number of seatbelts and, by law, must NOT be EXCEEDED. While in the Vehicle,
        please always fasten you seatbelts. It is the law!
        <br />
        <span class="chinese">车辆最大乘客人数以安全带为标准，禁止人员超载，并且在行驶中时刻系好安全带</span>
      </p>
      <p>
        In case the Vehicle will be returned exceptionally dirty a maximum charge of $400.00 CLEANING FEE may apply.
        <br />
        <span class="chinese">请保持车辆整洁，如有极度脏乱，将产生最高至$400 NZD清洁费</span>
      </p>
      <p>
        Ensure that the headlights and the reading lights in the car are turned off when leaving the vehicle. Flat battery will cost the relevant rescue fee and
        it will be the driver's responsibility to paid for.
        <br />
        <span class="chinese">在离开车辆时，请确认车大灯以及车内阅读灯等处以关闭状态。如导致电池没电，相关救援费用将由租赁者承担</span>
      </p>
      <p>
        Flat or damaged TIRES are driver’s responsibility of the driver and are the financial responsibility of the Authorized Driver(s).
        <br />
        <span class="chinese">轮胎爆胎或损还将视为司机的责任，产生的相关费用将由租赁者/司机赔付</span>
      </p>
      <p>
        Please return the car at the end of rental agreement period. Each hour past the Rental Period will be charged at $15 per hour for late return.
        <br />
        <span class="chinese">请按照合同规定日期和时间还车到门店，否则将会产生每小时$15 NZD罚金</span>
      </p>
      <p>
        Driving to South Island is NOT allowed, Please stay in North Island. Fine of $1,000 NZD will apply if so.
        <br />
        <span class="chinese">禁止将车辆开去南岛，请保持在北岛行驶，否则将产生 $1,000 NZD 罚金</span>
      </p>
    </div>
    <div class="table-bordered table-credit mt-3">
      <b-row>
        <b-col cols="9" class="pr-0">
          <h5>
            <u>PAYMENT FORM/CARD ON FILE</u>
            if apply [VISA or Mastercard ONLY]
          </h5>
        </b-col>
        <b-col class="chinese">信用卡信息登记（如适用）</b-col>
      </b-row>
      <b-row>
        <b-col cols="9" class="pr-0">Any Pre-Auth (Bond) will release back in 15 working days after returned the vehicle.</b-col>
        <b-col class="chinese">预授权押金将于还车后15个工作日返回.</b-col>
      </b-row>

      <label class="mt-2 mb-1">NAME ON CARD:</label>
      <input />
      <br />

      <label class="mb-1">CREDIT CARD:</label>
      <input />
      <br />
      <label class="mb-1">EXP:</label>
      <input />
      <br />
      <label class="mb-1">CVV:</label>
      <input />
      <br />
      <label class="mb-1">CARD TYPE:</label>
      <input list="card" />
      <datalist id="card">
        <option>VISA</option>
        <option>MasterCard</option>
      </datalist>
    </div>
    <h4>
      <label>Date:</label>
      <input type="date" class="border-0 mt-5" />
    </h4>
    <b-row class="h4">
      <b-col cols="6">
        <label>Name:</label>
        <input class="border-0 border-bottom" />
      </b-col>
      <b-col>
        Signature:
        <input class="border-0 border-bottom" />
      </b-col>
    </b-row>
    <hr class="bottom-line" />
    <div class="text-center">Page 2 of 3</div>
    <div style="page-break-after: always;"></div>
    <!-- Third page -->
    <b-row class="mt-5 terms">
      <b-col cols="6">
        <p>
          TERMS AND CONDITIONS: An agreement made between TT Group Limited
          (hereinafter called the owner) and the hirer whose particulars are entered in this
          agreement, it is hereby agreed as follows:
        </p>

        <p>
          CLAUSE (1) VEHICLE DESCRIPTION:
          The owner will let and the hirer will take to
          hire the motor vehicle described on this agreement.
        </p>
        <p>
          CLAUSE (2) DURATION OF HIRE: The term of the hire shall be for the period as
          described in this agreement. Any agreed extension to the hire shall be paid in full at
          the time of the extension and upon payment any such extension will form part of this
          agreement. The owner reserves the right to take possession of the vehicle if at any
          time the rental amount payable is outstanding, the cost of repossession of the vehicle
          and any costs incurred in recovering the outstanding amounts will be the
          responsibility- its of the hirer.
        </p>
        <p>
          CLAUSE (3) PERSON(S) WHO MAY DRIVE THE VEHICLE: The vehicle may be
          driven during the period of hire only by the persons described in this agreement and
          only if such person holds a full current driver’s license (particulars of which are given
          alongside his name and address) appropriate for the vehicle at the time when they are
          driving the vehicle
        </p>
        <div>
          CLAUSE (4) PAYMENTS BY HIRER:
          <ol type="a">
            <li>The hirer shall pay to the owner the amount indicated as the Balance Due on the agreement at the commencement of the hire.</li>
            <li>
              In addition to the payment referred to in clause 4 (a) of this agreement the hirer shall pay for any of the following where applicable:
              <ol type="i">
                <li>All petrol or other fuel used</li>
                <li>Any additional charges as outlined in Clause 12 that may be applicable to this agreement</li>
                <li>Any damage excess where damage has occurred to the vehicle</li>
                <li>
                  Any costs in relation with vehicle damage, including but not
                  limited to administration fee, lose of use fee, depreciation fee.
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div>
          CLAUSE (5) HIRERS OBLIGATIONS: The hirer shall ensure that:
          <ol type="a">
            <li>The water in the radiator and battery of the vehicle is maintained at the proper level</li>
            <li>The oil in the vehicle is maintained at the proper level</li>
            <li>The tyres are maintained at the proper pressure</li>
            <li>The hirer shall ensure that all reasonable care is taken in handling and parking the vehicle and that it is left securely locked when not in use.</li>
          </ol>
        </div>

        <div>
          CLAUSE (6) RETURN OF THE VEHICLE
          <ol type="a">
            <li>The hirer shall at or before the expiry of the term of hire deliver the vehicle to the owner’s place of business or the owner’s agent’s place of business, or obtain the owner’s consent to the continuation of hire.</li>
            <li>The owner shall have the right to terminate the hiring and take immediate possession of the vehicle if the hirer fails to comply with any of the terms of this agreement or if the vehicle is damaged. The termination of the hiring under the authority of this clause shall be without prejudice to the other rights of the owner and the rights of the hirer under this agreement or otherwise.</li>
          </ol>
        </div>

        <div>
          CLAUSE (7) OWNERS OBLIGATIONS
          <ol type="a" class="mb-0">
            <li>The owner shall supply the vehicle in a safe and roadworthy condition</li>
            <li>
              he owner shall be responsible for all ordinary and extraordinary costs of running the vehicle during the term of the hire except
              to the extent that by the terms of this agreement those costs are payable by the hirer.
            </li>
          </ol>NOTE: By virtue of clause 4 of this agreement, the cost of petrol and other fuel, but not oil, used during the term of the hire is the responsibility of the hirer.
        </div>

        <div class="mt-3">
          CLAUSE (8) MECHANICAL REPAIRS AND ACCIDENTS
          <ol type="a">
            <li>If the vehicle is damaged or requires repair or salvage, whether because of an accident or breakdown, the hirer shall advise the owner of the full circumstances by telephone as soon as practicable.</li>
            <li>Authority of the owner except to the extent that the repairs or salvage are necessary to prevent further damage to the vehicle or other property.</li>
            <li>The hirer shall ensure that no person shall interfere with the distance recorder or speedometer or except in any emergency any part of the engine, transmission, and braking or suspension systems of the vehicle.</li>
            <li>Punctures, glass and headlights are at the hirer expense.</li>
          </ol>
        </div>

        <div>
          CLAUSE (9) USE OF THE VEHICLE: The hirer shall not use or permit the vehicle
          to be used for the carriage of passengers for hire or reward unless the vehicle is hired
          with the knowledge of the owner for use in a passenger service licensed under the
          Transport Services Licensing Act 1989.
          <br />The hirer shall not:
          <ol type="a">
            <li>Sublet or hire the vehicle to any other person</li>
            <li>Permit the vehicle to be operated outside his authority</li>
          </ol>
        </div>
      </b-col>
      <b-col>
        <div>
          <ol type="a" start="3">
            <li>
              Operate the vehicle or permit it to be operated in circumstances that constitute
              an offence by the driver against Section 58 of the Transport Act 1962 (which
              relates to driving or attempting to drive with excess breath or blood alcohol or
              operate under the influence of any alcohol or drugs).
            </li>
            <li>Operate the vehicle under any of the exclusions detailed in Clause 10</li>
            <li>Operate the vehicle or permit it to be operated to propel or tow any other vehicle.</li>
            <li>
              Operate the vehicle or permit it to be operated in breach of the Transport Act
              1962, the Traffic Regulations 1976 or any other Act, regulations or bylaws to
              RoadTraffic
            </li>
            <li>Operate the vehicle or permit it to be operated for the transport of more than the number of passengers or more than the weight of goods specified in the certificate of loading for the vehicle.</li>
            <li>Operate the vehicle or permit it to be operated for the transport of animals, domestic or otherwise.</li>
          </ol>
        </div>
        <div>
          CLAUSE (10) INSURANCE
          <br />Subject to the exclusions set out below, the hirer and any driver authorized to drive the vehicle is fully indemnified in respect of any liability he might have to the owner in respect of loss or damage to the vehicle and its accessories and spare parts and any consequential loss of revenue or other expenses of the owner including towing and salvage costs associated with the recovery of the vehicle and its accessories and spare parts. Subject to the exclusions set out below, the hirer and any driver authorized
          to drive the vehicle are indemnified to the extent of $10,000,000 in respect of any liability he might have for damage to any property (including injury to any animal) belonging to any other person and arising out of the use of the vehicle.
          <ol type="a" class="mb-0">
            <li>The driver of the vehicle is under the influence of alcohol or any drug that affects his ability to drive the vehicle.</li>
            <li>The vehicle is in an unsafe or non­roadworthy condition that arose during the course of the hire and that caused or contributed to the damage or loss, and the hirer or driver was aware or ought to have been aware of the unsafe or non­ roadworthy condition of the vehicle.</li>
            <li>The vehicle is operated in any race, speed test, rally or contest</li>
            <li>The interior of the vehicle is damaged or soiled by cigarette burns, cuts, spillage or foreign materials</li>
            <li>Damage caused to, or by, Ski Racks and TyreChains</li>
            <li>The hirer is not a body corporate or department of state and the vehicle is driven by any person not named in this agreement</li>
            <li>The vehicle is driven by any person who at the time when he drives the vehicle is disqualified from holding or has never held a driver’s license appropriate for that vehicle.</li>
            <li>The vehicle is willfully or recklessly damaged by the hirer or any other person named in this agreement or driving the vehicle under the authority of the hirer, or is lost as a result of the willful or reckless behavior of the hirer or any such person</li>
            <li>
              The vehicle is operated on any of the following: All ski field roads, Ball Hut
              access (Mt Cook), Skippers Canyon (Queenstown), all beaches including Ninety
              Mile Beach, Coast Rd to Russell (Northland), Lake Waikaremoana Rd SH 38
              (Central North Island) any track or thoroughfare including gravel roads that is
              not officially designated as a road
            </li>
            <li>The vehicle is operated outside the term of hire or any agreed extension of that term.</li>
            <li>The vehicle is operated in breach of any of the terms and conditions of this contract.</li>
          </ol>It is agreed between the owner and the hirer that Section 11 of the Insurance Law
          Reform Act 1977 shall apply with respect to the above exclusions as if this clause
          constituted a contract of insurance
        </div>

        <div class="mt-3">
          CLAUSE (11) REPLACEMENT OF THE VEHICLE
          <br />In the event of an accident no refund or unused rental will be made. Provision of a replacement vehicle and insurance cover will be at the owner’s discretion.
        </div>
        <div class="mt-3">
          CLAUSE (12) ADDITIONAL CHARGES
          <ol type="a">
            <li>Administration fee for traffic fines, parking fines, toll road fines: NZD $30.00</li>
            <li>Administration fee for Vehicle damage: NZD $50.00</li>
            <li>
              Non-Payment Charges: NZD $30.00 contact fee (phone or email),
              interest fees at current bank lending rates and debt recovery fees as charged by
              any nominated agency will be charged to the hirer in the event of non payment
              of the rental charges.
            </li>
            <li>
              Lose of use fee applied base on daily rates of current agreement times days
              between date of returned the vehicle and date of repair job done.
            </li>
            <li>Late Charges: Late charges are charged at $15 perhour.</li>
          </ol>
        </div>
        <h4 class="mt-4 mb-0 pt-3 text-right">
          Signature:
          <input class="border-0 border-bottom" />
        </h4>
      </b-col>
    </b-row>
    <b-row no-gutters class="terms">
      Note to the Hirer: The owner must give you at least one copy of this agreement. A copy must be kept in the vehicle throughout the term of the hire and provided on demand by
      any police officer, or other authorized employee of the Ministry of Transport.
    </b-row>
    <hr class="bottom-line" />
    <div class="text-center">Page 3 of 3</div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            models: ["Tiida", "Blade", "Corolla Hatch", "Rav4", "X-Trail", "Pathfinder", "Highlander", "Alphard"],
        };
    },
};
</script>

<style scoped>
th label {
    margin: 0;
}
@media screen {
    .middle-c {
        width: 22cm;
        margin: 1cm auto 1cm;
    }
}
@page {
    size: auto; /* auto is the initial value */

    /* this affects the margin in the printer settings */
    margin: 1cm auto 1cm;
}

.table-bordered {
    border: 3px solid black;
    padding-left: 2px;
}
.table-bordered th,
.table-bordered td {
    border: 3px solid black;
}
.table input {
    border: none;
}
.table th,
.table td {
    padding: 0 0 0 2px;
}
.table-driver th,
.table-carinfo {
    font-size: 0.8rem;
}

th input {
    width: 60%;
}
.table-driver input {
    background-color: orange;
    position: relative;
    top: -5px;
    left: 3px;
    font-size: 1.2rem;
}
.table-period {
    font-size: 0.8rem;
}
.table-carinfo input {
    background-color: orange;
    position: relative;
    top: 3px;
    font-size: 1.2rem;
    font-weight: bold;
}
.table-carinfo th {
    width: 6.5rem;
}
.chinese {
    font-size: 0.6rem;
}
.tick-mark {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.5rem;
}
.bottom-line {
    background-color: white;
    height: 1px;
    margin-bottom: 0;
    border-top: 5px solid rgb(83, 71, 71);
    border-bottom: 2px solid rgb(43, 42, 42);
}
.table-credit input {
    border: none;
}
.table-credit label {
    font-weight: 500;
}
.terms {
    font-size: 0.9rem;
    line-height: 1.1rem;
}
</style>