<template>
  <div class="middle">
    <div>
      <h1 class="mt-3 fredoka text-center" v-if="$i18n.locale === 'en'">Cheap Car Hire in Mangere, Auckland</h1>
      <h1 class="mt-3 fredoka text-center" v-else>奥克兰最方便经济的租车</h1>
      <p
        class="text-center"
        v-if="$i18n.locale === 'en'"
      >Cheap car hires in Mangere, Auckland is made easy with Top Rental. With safe and pleasant travel experience, we offer a variety fleet of options. Book Your Trip!</p>
      <p class="text-center" v-else>途行租车可以让您在奥克兰轻松租赁性价比最高的汽车。我们为您提供多种选择，同时为您安全、愉快的旅行保驾护航。即刻开始预订您的旅行！</p>
      <b-row no-gutters class="mt-5 mb-4" align-h="center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53254.20055945377!2d174.7658124703994!3d-36.97937274548116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4faf29468dd5%3A0x6cbece2b92d332e7!2sTop%20Rental!5e0!3m2!1sen!2snz!4v1600913385712!5m2!1sen!2snz"
          width="100%"
          height="400"
          frameborder="0"
          style="border:0;"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </b-row>
      <b-row align-h="center">
        <b-col cols="11" class="border-right col-md-5 col-xl-4">
          <h3 class="fredoka mb-3 text-center">{{ $t("ContactDetails") }}</h3>
          <div class="pl-5 pl-md-3">
            <b-row class="f2">
              <b-col cols="1">
                <i class="tt-icon phone f2"></i>
              </b-col>
              <b-col cols="11">
                <b>{{ $t("NZ") }}:</b>
                <a href="tel:0800 668 628">0800 668 628</a>
              </b-col>
            </b-row>
            <b-row class="f2">
              <b-col cols="1">
                <i class="tt-icon freephone f2"></i>
              </b-col>
              <b-col cols="11">
                <b>{{ $t("AU") }}:</b>
                <a href="tel:1800 861 966">1800 861 966</a>
              </b-col>
            </b-row>
            <b-row class="f2">
              <b-col cols="1">
                <i class="tt-icon email f2"></i>
              </b-col>
              <b-col cols="11">
                <b>{{ $t("Email") }}:</b>
                <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a>
              </b-col>
            </b-row>
            <b-row class="f2">
              <b-col cols="1">
                <i class="tt-icon address f2"></i>
              </b-col>
              <b-col cols="11">
                <b>{{ $t("Address") }}:</b>
                <a href="https://maps.app.goo.gl/NorygiyKmtPjarJF8" target="_blank">40 Ascot Road, Mangere, Auckland 2022</a>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" class="text-center col-md-4 mt-5 mt-md-0 col-xl-4">
          <h3 class="fredoka mb-3">{{ $t("OpeningHours") }}</h3>
          <div class="mx-auto">
            <b-row no-gutters class="f2">
              <b-col>
                <b>{{ $t("Monday") }} - {{ $t("Sunday") }}</b>
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>9:00am {{ $t("To") }} 06:00pm</b>
              </b-col>
            </b-row>
            <!-- <b-row no-gutters class="f2">
              <b-col>
                <b>Monday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Tuesday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Wednesday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Thursday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Friday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Saturday:</b> 8:00 to 18:00
              </b-col>
            </b-row>
            <b-row no-gutters class="f2">
              <b-col>
                <b>Sunday:</b> 8:00 to 18:00
              </b-col> 
            </b-row>-->
          </div>
        </b-col>
        <b-col cols="12" class="border-left text-left col-md-3 mt-5 mt-md-0 col-xl-4">
          <h3 class="fredoka mb-3 text-center">{{ $t("MoreOptions") }}</h3>
          <b-row no-gutters class="f2" align-h="around">
            <b-col cols="3" md="4">
              <b>WhatsApp</b>
              <b-row class="mt-2">
                <b-col>
                  <b-img src="@/assets/contact/whatsapp.jpg" fluid-grow></b-img>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" md="4">
              <b>{{ $t("WeChat") }}</b>
              <b-row class="mt-2">
                <b-col>
                  <b-img src="@/assets/contact/wechat.jpg" fluid-grow></b-img>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Cheap Car Hire | Best Car Rental  Mangere Auckland",
        meta: [
            {
                name: "Keywords",
                content: "car rental auckland, hire car auckland, cheap car hire auckland, best car rental auckland, car rental mangere",
            },
            {
                name: "Description",
                content:
                    "Cheap car hires in Mangere, Auckland is made easy with Top Rental. With safe and pleasant travel experience, we offer a variety fleet of options. Book Your Trip!",
            },
        ],
    },
};
</script>

<style scoped>
.f2 {
    font-size: 1.2rem;
}
</style>