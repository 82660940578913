<template>
  <div class="middle" style="margin-top:20vh;">
    <b-row align-h="around" align-v="center">
      <b-button class="shadow-lg col-7 col-xl-2 col-md-5" variant="outline-light" @click="goto('/admin/order')">Order</b-button>
      <b-button class="shadow-lg col-7 col-xl-2 col-md-5" variant="outline-light" @click="goto('/admin/car')">Car</b-button>
      <b-button class="shadow-lg col-7 col-xl-2 col-md-5" variant="outline-light" @click="goto('/admin/price')">Price</b-button>
      <b-button class="shadow-lg col-7 col-xl-2 col-md-5" variant="outline-light" @click="goto('/admin/stop-sale')">StopSale</b-button>
      <b-button class="shadow-lg col-7 col-xl-2 col-md-5" variant="outline-light" @click="goto('/admin/shutdown')">Shutdown</b-button>
    </b-row>
  </div>
</template>

<script>
export default {
    methods: {
        goto(path) {
            this.$router.push(path);
        },
    },
};
</script>

<style scoped>
.btn {
    line-height: 10rem !important;
    font-size: 3rem;
    margin-top: 15px;
}
.btn:nth-child(3n + 1) {
    background-color: #ffcb74;
}
.btn:nth-child(3n + 2) {
    background-color: #ea907a;
}
.btn:nth-child(3n + 3) {
    background-color: #4f8a8b;
}
</style>