<template>
  <div class="middle">
    <div>
      <b-img src="@/assets/support/support.jpg" fluid-grow alt="Support"></b-img>
    </div>

    <div>
      <div class="about mx-auto">
        <p class="fredoka h1">
          {{ $t("Supportand") }}
          <span class="soft-blue">{{ $t("TopRental") }}</span>
        </p>
        <p>{{ $t("Hereisall") }}</p>
      </div>
      <div class="my-5">
        <b-row no-gutters align-h="center">
          <b-col cols="11" class="img-container col-md-4" @click="goto('/shuttle')">
            <b-img fluid src="@/assets/support/shuttle-info.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">接驳车信息</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="goto('/bond')">
            <b-img fluid src="@/assets/support/bond-info.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">信用卡&押金</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="goto('/insurance')">
            <b-img fluid src="@/assets/support/insurance-info.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">保险种类&覆盖范围</span>
          </b-col>
        </b-row>

        <b-row no-gutters align-h="center">
          <b-col cols="11" class="img-container col-md-4" @click="goto('/pickup')">
            <b-img fluid src="@/assets/support/pickup.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">取车指引</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="goto('/onroad')">
            <b-img fluid src="@/assets/support/onroad.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">驾车须知</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="goto('/dropoff')">
            <b-img fluid src="@/assets/support/return.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">还车指引</span>
          </b-col>
        </b-row>

        <b-row no-gutters align-h="center">
          <b-col cols="11" class="img-container col-md-4" @click="gotoOut('https://www.nzta.govt.nz/resources/driving-in-nz/')">
            <b-img fluid src="@/assets/support/drivesafe.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">安全驾驶</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="gotoOut('https://www.newzealand.com/int/trips-and-driving-itineraries/north-island/')">
            <b-img fluid src="@/assets/support/view.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">发现新西兰</span>
          </b-col>

          <b-col cols="11" class="img-container col-md-4" @click="gotoOut('https://www.aa.co.nz/membership/roadservice-breakdown-assistance/')">
            <b-img fluid src="@/assets/support/AA.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">道路救援</span>
          </b-col>
        </b-row>
        <b-row no-gutters align-h="center">
          <b-col cols="11" @click="gotoOut('https://www.metservice.com/')" class="img-container col-md-6">
            <b-img fluid src="@/assets/support/weather.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">天气预报</span>
          </b-col>

          <b-col cols="11" @click="gotoOut('https://www.journeys.nzta.govt.nz/')" class="img-container col-md-6">
            <b-img fluid src="@/assets/support/planner.jpg" class="p-0 support-trans"></b-img>
            <span v-if="$i18n.locale === 'zh'" class="offset-4" style="line-height: 3rem;font-weight: bold;">路线计划</span>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        goto(route) {
            this.$router.push(route);
        },
        gotoOut(url) {
            window.location.href = url;
        },
    },
    watch: {},
};
</script>

<style scoped >
.about {
    font-size: 1.2rem;
    width: 88%;
    text-align: center;
    margin-top: 2rem;
}
.img-container {
    overflow: hidden;
}
.support-trans {
    transition: transform 0.5s ease-in-out;
    transform: translateZ(0);
}
.support-trans:hover {
    transform: translateZ(0) scale(1.1);
    cursor: pointer;
}
</style>